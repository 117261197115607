/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { PureComponent, createRef } from 'react';
// redux
import { connect } from '../../../../../../utils/redux';
import {
  deleteActivityReq,
  linkActivityReq,
  unlinkActivityReq,
  selectActivity,
  copyActivityReq,
} from '../store/actions';
import { suppliersForSelect } from '../../../Suppliers/store/selectors';
import {
  getSupplierReq,
  updateSupplierReq,
  updateSupplierInTabReq,
  updateSuppliersReq,
} from '../../../Suppliers/store/actions';
import { selectedIds } from '../store/selectors';
import { supplierFunctions } from '../../../SupplierFunctions/store/selectors';
// types
import { Action } from '../../../../../../store/types';
import { ActivityTypes } from '../store/types';
import { IProduction, IActivity } from '../../store/types';
import { SupplierFunctionListState } from '../../../SupplierFunctions/store/types';
import { SupplierListState, SupplierItemState } from '../../../Suppliers/store/types';
import { IWrappedProps } from '../../../withAcl';
// components
import { ISupplierFormValues } from '../../../../../common/form/selectWithTabs/CreateSupplierForm';
import { Link } from 'react-router-dom';
import FunctionSelector from './FunctionSelector';
import SupplierSelector from './SupplierSelector';
import {
  createActivityReq,
  updateActivityReq,
  updateActivityRangeReq,
  updateItemOfAllActivities,
} from '../store/actions';
import GRID from '../../../../../common/grid';
import Delete from '../../../../../common/grid/actions/Delete';
import Button from '../../../../../common/grid/actions/Button';
import Timeline, { BarTypes } from './Timeline/Timeline';
import TimelineHeader from './Timeline/TimelineHeader';
import Datepicker from '../../../../../common/form/datepicker/Datepicker';
import Text from '../../../../../common/form/Text';
import Checkbox from '../../../../../common/form/CheckboxWithRef';
import Popover from '../../../../../common/popover/Popover';
import OutlineBtn from '../../../../../common/buttons/OutlineBtn';
import { ViewModes } from '../Item';
// utils
import _ from 'lodash';
import si from 'seamless-immutable';
import moment from 'moment';
import { toast } from 'react-toastify';
import { isAmPmTimeFormat, getTimeFormat } from '../../../../../../utils/timeFormatter';
import { extractKeyValue } from '../../../../../../utils/collection';
import * as utils from '../utils';
import withVisualContext from '../../../../../../contexts/withVisualContext';
import { IVisualContext } from '../../../../../../contexts/VisualContext';
// other
import routes from '../../../../../../routes';
// styles
import * as styles from './styles';
import * as mainStyles from '../../../../../../styles/styles';
import * as formStyles from '../../../../../common/form/styles';
import arrowBlackBottom from './images/arrowBlackBottom.svg';
import letterA from '../../../../../../images/icons/letterA.svg';
import letterL from '../../../../../../images/icons/letterL.svg';
import letterM from '../../../../../../images/icons/letterM.svg';

export interface IProps extends IWrappedProps {
  activities: any[];
  workDay: any;
  isEditMode?: boolean;
  viewMode: ViewModes;
  onEditTransportStart?: (t: any) => void;
  supplierFunctions?: SupplierFunctionListState;
  suppliersForSelect?: SupplierListState;
  updateSupplierReq: Action<{}>;
  updateSupplierInTabReq: Action<{}>;
  updateSuppliersReq: Action<{}>;
  createActivityReq?: Action<{}>;
  addSupplierFunction: Action<{}>;
  createSupplierFunction: Action<{}>;
  getSupplierFunctions: Action<{}>;
  getSupplierReq: Action<{}>;
  refreshSupplierFunctions: Action<{}>;
  updateActivityReq?: Action<{}>;
  copyActivityReq?: Action<{}>;
  updateActivityRangeReq?: Action<{}>;
  updateItemOfAllActivities?: Action<{}>;
  selectActivity?: Action<{}>;
  production: IProduction;
  subproductions?: IProduction[];
  deleteActivityReq?: Action<{}>;
  linkActivityReq?: Action<{}>;
  unlinkActivityReq?: Action<{}>;
  isPrint?: boolean;
  additionalPrintHeader?: any;
  isSelectMode?: boolean;
  selectedIds: number[];
  allSuppliers: any;
  allFunctions: any;
  visual: IVisualContext;
  supplier: SupplierItemState;
  hasPermissionToAdd?: boolean;
  isStickyGridHeader?: boolean;
}

export interface IState {
  activityIdSelected: number | null;
  timelineValueElements: any;
  editItemId: any;
  clonePopoverId: number | null;
  cloneDate: any;
  openTransportsInfo: number[];
  timeRangeArr: number[];
}

const TIMELINE_SCHEMA = [
  '6:00',
  '8:00',
  '10:00',
  '12:00',
  '14:00',
  '16:00',
  '18:00',
  '20:00',
  '22:00',
  '24:00',
  '2:00',
  '4:00',
];
const TIMELINE_SCHEMA_AM_PM = ['6am', '8am', '10am', '12am', '2pm', '4pm', '6pm', '8pm', '10pm', '12pm', '2am', '4am'];

jsx;
class DayTable extends PureComponent<IProps, IState> {
  private selectSupplierRef: any;
  private startActivityRef: any;
  private remarksRef: any;
  private applyActionRef: any;

  constructor(props: IProps) {
    super(props);

    this.selectSupplierRef = createRef();
    this.remarksRef = createRef();
    this.applyActionRef = createRef();
    this.startActivityRef = createRef();

    this.state = {
      activityIdSelected: null,
      timelineValueElements: null,
      editItemId: null,
      clonePopoverId: null,
      cloneDate: null,
      openTransportsInfo: [],
      timeRangeArr: [],
    };
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    const { isEditMode } = this.props;
    const { editItemId } = this.state;

    if (!isEditMode && editItemId) {
      this.setState({ editItemId: null });
    }
  }

  componentDidMount = () => {
    //hack update links after render all elements
    /*setTimeout(() => {
      this.forceUpdate();
    }, 5000);*/
    window.addEventListener('click', this.clearSlectedActiveId);
  };

  componentWillUnmount = () => {
    window.removeEventListener('click', this.clearSlectedActiveId);
  };

  clearSlectedActiveId = () => {
    this.setState({ activityIdSelected: null });
  };

  getActivityById = (activityId: any) => {
    const { activities } = this.props;
    return _.find(activities, { id: activityId });
  };

  getLinkRange = (activityId: any) => {
    const activity = this.getActivityById(activityId);
    return _.mapValues(activity.range, (value) => moment.utc(value).valueOf());
  };

  linkActivities = (activityId: number, linkToActivityId: number | null) => {
    if (linkToActivityId) {
      const { production, linkActivityReq = _.identity } = this.props;
      const range = this.getLinkRange(activityId);

      linkActivityReq({
        ownerId: production.id,
        parentActivityId: linkToActivityId,
        activityId,
        range,
        direction: 'tail',
      }).then(() => {
        this.forceUpdate();
      });

      this.clearSlectedActiveId();
    }
  };

  unlinkActivity = (activityId: any) => {
    const { production, unlinkActivityReq = _.identity } = this.props;
    unlinkActivityReq({ ownerId: production.id, activityId }).then(() => {
      this.forceUpdate();
    });
  };

  addTimelineValueElement = (activity: any, element: any) => {
    this.setState((state) => {
      const timelineValueElements = state.timelineValueElements || {};
      return {
        timelineValueElements: { ...timelineValueElements, [activity.id]: element },
      };
    });
  };
  addToTimeRangeArr = (rageFrom: number) => {
    this.setState((state) => {
      return { timeRangeArr: [...state.timeRangeArr, rageFrom] };
    });
  };

  onEditStart = (activity: any) => {
    const { onEditTransportStart = _.identity } = this.props;
    const editStart = activity.type === ActivityTypes.TRANSPORT ? onEditTransportStart : this.editActivityStart;
    editStart(activity);
  };

  editActivityStart = (activity: any) => {
    const { production } = this.props;
    const isSubInMain = production && production.isMain && !activity.production.isMain;
    const isLocationInArea =
      production && !production.isMain && production.canHaveSub && !activity.production.canHaveSub;
    const isMainActivity = [ActivityTypes.REHEARSAL, ActivityTypes.SHOW].includes(activity.type);
    if (isSubInMain) {
      toast.warn(`You cannot edit ${this.props.visual.labels.subproduction} activity from main production schedule`);
    } else if (isMainActivity) {
      toast.warn('You cannot edit main activity from schedule. You can do this on production edit page.');
    } else if (isLocationInArea) {
      toast.warn(
        `You cannot edit ${this.props.visual.labels.subSubproduction} activity from ${
          this.props.visual.labels.subproduction
        } schedule`
      );
    } else {
      this.setState({
        editItemId: _.get(activity, 'id'),
      });
    }
  };

  onEditEnd = () => {
    this.setState({ editItemId: null });
  };

  openClonePopover = (activity: any) => {
    this.setState({
      clonePopoverId: activity.id,
      cloneDate: activity.range.from,
    });
  };

  closeClonePopover = () => {
    this.setState({ clonePopoverId: null });
  };

  onCloneDateChange = (date: any) => {
    this.setState({ cloneDate: date });
  };

  cloneActivity = (activity: any) => {
    const { copyActivityReq, production } = this.props;
    const { cloneDate } = this.state;
    const duration = activity.range.to - activity.range.from;
    copyActivityReq({
      ownerId: production.id,
      ...activity,
      ranges: [
        {
          from: cloneDate,
          to: cloneDate + duration,
        },
      ],
    });
    this.closeClonePopover();
  };

  getProductionRange = () => {
    const { production } = this.props;

    return {
      from: moment.utc(production.loadIn.from),
      to: moment.utc(production.loadOut.to),
    };
  };

  getDefaultTime = () => {
    const { workDay } = this.props;
    const productionRange = this.getProductionRange();

    const range = utils.limitRange(workDay, productionRange);

    let from = moment
      .utc(workDay.from)
      .startOf('day')
      .add(8, 'hours')
      .valueOf();

    from = utils.tValue(range.from) > from ? utils.tValue(range.from) : from;

    let to = moment
      .utc(from)
      .add(2, 'hours')
      .valueOf();

    to = utils.tValue(range.to) < to ? utils.tValue(range.to) : to;

    return { from, to };
  };

  prepareSaveData = (data: any) => {
    if (data.suppliers.includes(0)) {
      data = (si as any).set(data, 'allSuppliers', true);
      data = (si as any).update(data, 'suppliers', (suppliers: any) => _.compact(suppliers));
    } else {
      data = (si as any).set(data, 'allSuppliers', false);
    }

    if (data.functions.includes(0)) {
      data = (si as any).set(data, 'allFunctions', true);
      data = (si as any).update(data, 'functions', (suppliers: any) => _.compact(suppliers));
    } else {
      data = (si as any).set(data, 'allFunctions', false);
    }

    return data;
  };

  isRowEditable = (activity: IActivity) => {
    const { production } = this.props;
    const isSubInMain = production.isMain && !activity.production.isMain;
    const isMainActivity = [ActivityTypes.REHEARSAL, ActivityTypes.SHOW].includes(activity.type);
    const isLocationInArea =
      production && !production.isMain && production.canHaveSub && !activity.production.canHaveSub;
    return !(isMainActivity || isSubInMain || isLocationInArea);
  };

  getTimelineScheme = () => {
    return isAmPmTimeFormat() ? TIMELINE_SCHEMA_AM_PM : TIMELINE_SCHEMA;
  };

  toggleTransportInfo = (transportId: number) => {
    const { openTransportsInfo } = this.state;

    this.setState({
      openTransportsInfo: openTransportsInfo.includes(transportId)
        ? openTransportsInfo.filter((id) => transportId !== id)
        : [...openTransportsInfo, transportId],
    });
  };

  renderTransportInfo = ({ transport, type, id, ...data }: any, index: number) => {
    const { openTransportsInfo } = this.state;
    const { isPrint, isEditMode } = this.props;

    if (type === ActivityTypes.TRANSPORT && (isPrint || openTransportsInfo.includes(id))) {
      const margin = isPrint ? 12 : isEditMode ? 16 : 9.5;
      return (
        <div css={styles.transportAdditionalInfoBox(margin)}>
          <div css={styles.gateRow}>
            <div css={styles.transportTitle}>Gate</div>
            <div css={styles.transportValue}>{_.get(transport, 'gate.name')}</div>
          </div>

          {_.get(transport, 'vehicles', []).map((vehicle: any) => {
            return (
              <div key={vehicle.id} css={styles.transportRow}>
                <div css={styles.transportTitle}>{_.get(vehicle, 'type.name')}</div>
                <div css={styles.transportValue}>{_.get(vehicle, 'licence')}</div>
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  };

  handleCreateFunction = (values: Record<string, string>) => {
    this.props.addSupplierFunction({ values });
    this.props.refreshSupplierFunctions();
  };

  handleCreateSupplier = (values: ISupplierFormValues) => {
    const { createSupplierFunction } = this.props;
    createSupplierFunction({
      data: { ...values },
    });
  };
  handleAddFunctionsToSuppliers = async (suppliers: any, funcForAdding: number[]) => {
    suppliers.map((supplier: number) =>
      this.props.updateSuppliersReq({ supplierId: supplier, newFunctions: funcForAdding })
    );
  };
  handleConnectFunctionsASupplier = (id: number, funcForAdding: number[]) => {
    this.props.updateSuppliersReq({ supplierId: id, newFunctions: funcForAdding });
  };

  render() {
    const { ActionList, ForwardedApplyAction, Grid, Actions, ForwardedCancelAction } = GRID;
    const {
      activities,
      isEditMode = false,
      selectedIds = [],
      supplierFunctions,
      suppliersForSelect,
      createActivityReq = _.identity,
      updateActivityReq = _.identity,
      updateActivityRangeReq = _.identity,
      updateItemOfAllActivities = _.identity,
      deleteActivityReq = _.identity,
      selectActivity = _.identity,
      production,
      workDay,
      isPrint = false,
      additionalPrintHeader = null,
      isSelectMode,
      hasPermissionToAdd,
      subproductions,
      isStickyGridHeader,
      viewMode,
    } = this.props;

    const { clonePopoverId, cloneDate, openTransportsInfo } = this.state;

    const clearActivityData = _.partial(_.omit, _, ['intersection', 'group']);

    const productionRange = this.getProductionRange();

    const gridShema = [
      {
        width: isPrint ? 10 : 7,
        title: 'Start',
        render: (activity: any) => {
          const daysBefore = activity.intersection.before ? -Math.abs(activity.intersection.before) : '';
          const startTime = moment.utc(activity.range.from).format(getTimeFormat());

          return (
            <div css={styles.startDateTimeCell}>
              <div css={styles.startDateTimeMark}>{daysBefore}</div>
              {startTime}
            </div>
          );
        },
        edit: {
          initValue: ({ range }: any) => {
            const defaultTimeRange = this.getDefaultTime();

            return _.get(range, 'from') || defaultTimeRange.from;
          },
          reducer: (activity: any, value: any) => {
            return (si as any).setIn(activity, ['range', 'from'], moment.utc(value).valueOf());
          },
          render: (activity: any, { onChange }: any, error: any) => {
            return (
              <div>
                <Datepicker
                  value={activity.range.from}
                  onChange={onChange}
                  dateFormat={'DD-MM'}
                  range={productionRange}
                  tabIndex={0}
                  ref={this.startActivityRef}
                />
              </div>
            );
          },
        },
      },

      {
        width: 7,
        visible: 'edit',
        title: 'End',
        render: (activity: any) => {
          const daysAfters = activity.intersection.after ? Math.abs(activity.intersection.after) : '';
          const startTime = moment.utc(activity.range.to).format(getTimeFormat());
          return (
            <div css={styles.startDateTimeCell}>
              <div css={styles.startDateTimeMark}>{daysAfters}</div>
              {startTime}
            </div>
          );
        },
        edit: {
          initValue: ({ range }: any) => {
            const defaultTimeRange = this.getDefaultTime();
            return _.get(range, 'to') || defaultTimeRange.to;
          },
          reducer: (activity: any, value: any) => {
            return (si as any).setIn(activity, ['range', 'to'], moment.utc(value).valueOf());
          },
          render: (activity: any, { onChange }: any, error: any) => {
            return (
              <Datepicker value={activity.range.to} onChange={onChange} dateFormat={'DD-MM'} range={productionRange} />
            );
          },
        },
      },

      {
        title: 'Activity',
        render: ({ id, type, name, ...data }: any) => {
          if (ActivityTypes.ACTIVITY === type) {
            return name;
          } else if (ActivityTypes.TRANSPORT === type) {
            const vehiclesCount = _.get(data, 'transport.vehicles.length');
            const { openTransportsInfo } = this.state;
            const isOpen = openTransportsInfo.includes(id);
            return (
              <div
                css={style.transportNameBox}
                onClick={(e: any) => {
                  this.toggleTransportInfo(id);
                }}
              >
                <div>
                  {name}
                  {'\u00A0'}({vehiclesCount})
                </div>
                {!isPrint && <div css={style.transportNameArrow(isOpen)} />}
              </div>
            );
          } else {
            name = name ? `(${name})` : '';
            return `${type} ${name}`;
          }
        },
        edit: {
          initValue: ({ name = '' }: any) => {
            return name;
          },
          reducer: (activity: any, value: any) => {
            return (si as any).set(activity, 'name', value);
          },
          render: (activity: any, { onChange }: any, error: any) => {
            const handleChange = (e: any) => {
              onChange(_.get(e, 'currentTarget.value'));
            };
            return <Text value={activity.name} onChange={(e) => handleChange(e)} />;
          },
        },
      },

      {
        title: this.props.visual.labels.Function,
        serializer: ({ functions, allFunctions, type }: any) => {
          return allFunctions
            ? `All production ${this.props.visual.labels.functions}`
            : extractKeyValue(functions, 'name').join(', ');
        },

        edit: {
          initValue: ({ functions = [], allFunctions }: any) => {
            return allFunctions ? [0] : extractKeyValue(functions, 'id');
          },
          reducer: (activity: any, value: any) => {
            const lastValue = _.last(value);
            const isAll = lastValue === 0;
            value = isAll ? [0] : _.compact(value);
            return (si as any).set(activity, 'functions', value);
          },
          render: (activity: any, { onChange }: any, error: any) => {
            const functions = [
              { id: 0, name: `All production ${this.props.visual.labels.functions}` },
              ..._.get(supplierFunctions, 'data', []),
            ];
            const allSuppliers = _.get(suppliersForSelect, 'data', []);
            return (
              <FunctionSelector
                noSelectedMsg="Not selected"
                activity={activity}
                allSuppliers={allSuppliers}
                items={functions}
                selected={_.get(activity, 'functions', [])}
                scheme={{ id: 'id', title: 'name' }}
                onChange={onChange}
                selectAllBtn={false}
                connectFunctionToChosenSuppliers={(funcForAdding) => {
                  this.handleAddFunctionsToSuppliers(activity.suppliers, funcForAdding);
                }}
                onBlur={() => {
                  this.selectSupplierRef && this.selectSupplierRef.current && this.selectSupplierRef.current.focus();
                }}
              />
            );
          },
        },
      },

      {
        title: 'Supplier',
        serializer: ({ suppliers, allSuppliers, type }: any) => {
          return allSuppliers ? 'All production suppliers' : extractKeyValue(suppliers, 'name').join(', ');
        },
        edit: {
          initValue: ({ suppliers = [], allSuppliers }: any) => {
            return allSuppliers ? [0] : extractKeyValue(suppliers, 'id');
          },
          reducer: (activity: any, value: any) => {
            const lastValue = _.last(value);
            const isAll = lastValue === 0;
            value = isAll ? [0] : _.compact(value);
            return (si as any).set(activity, 'suppliers', value);
          },
          render: (activity: any, { onChange }: any = _.identity, error: any) => {
            const suppliers = [{ id: 0, name: 'All production suppliers' }, ..._.get(suppliersForSelect, 'data', [])];
            const functions = [
              { id: 0, name: `All production ${this.props.visual.labels.functions}` },
              ..._.get(supplierFunctions, 'data', []),
            ];
            const allSuppliers = _.get(suppliersForSelect, 'data', []);
            return (
              <SupplierSelector
                hasPermissionToAdd={hasPermissionToAdd}
                activity={activity}
                allSuppliers={allSuppliers}
                noSelectedMsg="Not selected"
                selectAllBtn={false}
                scheme={{ id: 'id', title: 'name' }}
                selected={_.get(activity, 'suppliers', [])}
                onChange={onChange}
                items={suppliers}
                ref={this.selectSupplierRef}
                onCreate={this.handleCreateSupplier}
                supplierFunctions={functions}
                updateSupplier={this.props.updateSupplierInTabReq}
                connectFunctionsToSupplier={this.handleConnectFunctionsASupplier}
                onBlur={() => {
                  this.remarksRef && this.remarksRef.current && this.remarksRef.current.focus();
                }}
              />
            );
          },
        },
      },

      {
        width: 8,
        visible: () => {
          if (isPrint) {
            return production.isMain && production.sub && production.sub.length > 0;
          }
          return production.isMain && subproductions && subproductions.length > 0;
        },
        title: 'Type',
        render: (activity: any) => {
          return _.get(activity, 'production.isMain') ? (
            <img src={letterM} alt="" />
          ) : _.get(activity, 'production.canHaveSub') ? (
            <span css={styles.linkIn}>
              <Link
                to={routes.Production.replace(':id', _.get(activity, 'production.id'))}
                tabIndex={-1}
                css={style.typeCell}
              >
                <img src={letterA} alt="" />
                {_.get(activity, 'production.name')}
              </Link>
            </span>
          ) : _.get(activity, 'production.name') ? (
            <span css={styles.linkIn}>
              <Link
                to={routes.Production.replace(':id', _.get(activity, 'production.id'))}
                tabIndex={-1}
                css={style.typeCell}
              >
                <img src={letterL} alt="" />
                {_.get(activity, 'production.name')}
              </Link>
            </span>
          ) : (
            <span />
          );
        },
      },

      {
        width: 8,
        visible: () => {
          if (isPrint) {
            return !production.isMain && production.canHaveSub && production.sub && production.sub.length > 0;
          }
          return !production.isMain && production.canHaveSub && subproductions && subproductions.length > 0;
        },
        title: 'Type',
        render: (activity: any) => {
          return _.get(activity, 'production.canHaveSub') ? (
            <img src={letterA} alt="" />
          ) : _.get(activity, 'production.name') ? (
            <span css={styles.linkIn}>
              <Link
                to={routes.Production.replace(':id', _.get(activity, 'production.id'))}
                tabIndex={-1}
                css={style.typeCell}
              >
                <img src={letterL} alt="" />
                {_.get(activity, 'production.name')}
              </Link>
            </span>
          ) : (
            <span />
          );
        },
      },

      {
        width: isEditMode || isPrint ? 30 : 40,
        title: <TimelineHeader scheme={this.getTimelineScheme()} />,
        headStyle: styles.timelineCell,
        cellStyle: styles.timelineCell,
        render: (activity: any) => {
          const functionIds = extractKeyValue(activity.functions, 'id');

          const color =
            activity.color ||
            (functionIds.length === 1
              ? _.get(
                  _.find(_.get(supplierFunctions, 'data', []), { id: _.head(functionIds) }),
                  'color.code',
                  mainStyles.Colors.DarkGrey
                )
              : mainStyles.Colors.DarkGrey);

          let childColor = 'red';
          if (activity.child) {
            const childFunctionIds = extractKeyValue(activity.child.functions, 'id');
            childColor = _.get(
              _.find(_.get(supplierFunctions, 'data', []), { id: _.head(childFunctionIds) }),
              'color.code',
              mainStyles.Colors.DarkGrey
            );
          }
          const groupElements = _.get(activity, 'children.length')
            ? _.reduce(
                activity.children,
                (result, item) => {
                  return { ...result, [item.id]: _.get(this.state, ['timelineValueElements', item.id]) };
                },
                {}
              )
            : null;

          const barType = activity.type === BarTypes.Transport ? BarTypes.Transport : BarTypes.Activity;

          return (
            <Timeline
              activity={activity}
              color={color}
              barType={barType}
              scheme={this.getTimelineScheme()}
              workDay={this.props.workDay}
              limitRange={{
                from: moment.utc(production.loadIn.from).valueOf(),
                to: moment.utc(production.loadOut.to).valueOf(),
              }}
              isEditMode={this.isRowEditable(activity) && isEditMode}
              onChange={(range) => {
                const update = clearActivityData({ ...activity, range });
                updateItemOfAllActivities({ data: update });

                updateActivityRangeReq({ ownerId: production.id, id: activity.id, ...range }).then((response: any) => {
                  this.forceUpdate();
                });
              }}
              isSelected={this.state.activityIdSelected === activity.id}
              onSelect={(e: any) => {
                if (!activity.child) {
                  this.setState({ activityIdSelected: activity.id });
                }
              }}
              onDeselect={(e: any) => {
                this.clearSlectedActiveId();
              }}
              onClick={(e: any) => {
                this.linkActivities(activity.id, this.state.activityIdSelected);
              }}
              onUnlink={(activityId: any) => {
                this.unlinkActivity(activityId);
              }}
              htmlElementCollector={(el) => {
                this.addTimelineValueElement(activity, el);
              }}
              groupElements={groupElements}
              childColor={childColor}
            />
          );
        },
        edit: {
          render: (activity: any, { onChange }: any = _.identity, error: any) => {
            return <div />;
          },
        },
      },

      {
        width: 10,
        title: 'Remarks',
        render: (activity: any) => {
          return <div data-rh={activity.remarks}>{activity.remarks}</div>;
        },
        edit: {
          initValue: ({ remarks = '' }: any) => {
            return remarks;
          },
          reducer: (activity: any, value: any) => {
            return (si as any).set(activity, 'remarks', value);
          },
          render: (activity: any, { onChange }: any = _.identity, error: any) => {
            const handleChange = (e: any) => {
              onChange(_.get(e, 'currentTarget.value'));
            };
            return (
              <div css={formStyles.formFieldBox}>
                <textarea
                  ref={this.remarksRef}
                  rows={1}
                  cols={15}
                  value={_.get(activity, 'remarks', '')}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            );
          },
        },
      },

      {
        title: 'Selected',
        // width: 5,
        visible: 'edit',
        headStyle: css`
          text-align: center;
        `,
        hideIf: !isSelectMode,
        render: (activity: any) => {
          const isSelectable =
            production &&
            activity.production &&
            production.id === activity.production.id &&
            activity.type === ActivityTypes.ACTIVITY;
          const isSelected = isSelectable && selectedIds.includes(activity.id);
          return (
            <label css={style.checkboxCell}>
              <Checkbox checked={isSelected} onChange={() => selectActivity(activity.id)} disabled={!isSelectable} />
            </label>
          );
        },
      },

      {
        width: 5,
        visible: 'edit',
        title: '',
        render: (activity: IActivity) => {
          if (!this.isRowEditable(activity)) return <div />;
          return clonePopoverId === activity.id ? (
            <Popover isOpen onClickOutside={this.closeClonePopover} autoHeight>
              <div>
                <Datepicker
                  input={false}
                  value={cloneDate}
                  onChange={this.onCloneDateChange}
                  dateFormat={'DD-MM'}
                  timeFormat={false}
                  range={productionRange}
                />
                <OutlineBtn
                  title="Clone activity"
                  onClick={() => this.cloneActivity(activity)}
                  css={style.cloneButton}
                />
              </div>
            </Popover>
          ) : (
            <ActionList>
              <Delete
                onConfirm={(e) => {
                  deleteActivityReq({ ownerId: production.id, id: activity.id });
                }}
              />
              <Button title="Edit" onClick={(e) => this.onEditStart(activity)} />
              <Button title="Clone" onClick={(e) => this.openClonePopover(activity)} />
            </ActionList>
          );
        },

        edit: {
          render: (activity: any, { onClear, onClose }: any, error: any) => {
            const values = { ...activity, production: production.id, parent: production.id };
            return (
              <Actions>
                <ForwardedApplyAction
                  ref={this.applyActionRef}
                  onClick={() => {
                    const data = { ownerId: production.id, ...clearActivityData(values) };
                    if (values.id) {
                      updateActivityReq(this.prepareSaveData(data)).then(() => {
                        this.forceUpdate();
                      });
                      this.startActivityRef && this.startActivityRef.current && this.startActivityRef.current.focus();
                    } else {
                      onClose();
                      createActivityReq(this.prepareSaveData(data)).then((res: any) => {
                        onClear();
                      });
                    }
                    this.startActivityRef && this.startActivityRef.current && this.startActivityRef.current.focus();
                    onClose();
                  }}
                />
                {_.get(activity, 'id') && <ForwardedCancelAction onClick={onClose} />}
              </Actions>
            );
          },
        },
      },
    ];

    const showedActivities = activities.filter(
      (activity) => !(activity.production.hideShows && activity.type == ActivityTypes.SHOW)
    );

    return (
      <div css={isPrint ? {} : styles.dayTableBox}>
        <Grid
          isEditMode={isEditMode}
          editItemId={this.state.editItemId}
          onEditStart={this.onEditStart}
          onEditEnd={this.onEditEnd}
          isPrintView={isPrint}
          isStickyGridHeader={isStickyGridHeader}
          shema={gridShema}
          isSelectMode={isSelectMode}
          isTransportMode={viewMode === ViewModes.transport}
          data={showedActivities}
          canDrag={({ id, type, ...activity }: any) => {
            return (
              isEditMode &&
              [ActivityTypes.ACTIVITY, ActivityTypes.TRANSPORT].includes(type) &&
              production.id === _.get(activity, 'production.id')
            );
          }}
          dragGridData={{ production, workDay }}
          additionalPrintHeader={additionalPrintHeader}
          renderAfterRowContent={this.renderTransportInfo}
        />
      </div>
    );
  }
}

export default connect(
  { supplierFunctions, suppliersForSelect, selectedIds },
  {
    updateActivityReq,
    updateActivityRangeReq,
    updateItemOfAllActivities,
    createActivityReq,
    deleteActivityReq,
    linkActivityReq,
    unlinkActivityReq,
    selectActivity,
    copyActivityReq,
    getSupplierReq,
    updateSupplierReq,
    updateSupplierInTabReq,
    updateSuppliersReq,
  }
)(withVisualContext(DayTable));

const style = {
  transportNameBox: css({
    display: 'flex',
    cursor: 'pointer',
  }),

  transportNameArrow: (isOpen: boolean = false) =>
    css({
      backgroundColor: 'red',
      background: `url(${arrowBlackBottom}) 50% 50% no-repeat`,
      transform: `rotate(${isOpen ? 180 : 0}deg)`,
      width: '20px',
      height: '20px',
      marginLeft: '10px',
    }),

  checkboxCell: css`
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 5px;
  `,
  typeCell: css`
    display: flex;
    align-items: center;
    column-gap: 3px;
    justify-content: flex-start;
    text-decoration: underline;
  `,
  cloneButton: css`
    background: ${mainStyles.Colors.Green};
    color: white;
    width: 100%;
    border-radius: 0;
    &:hover {
      background: ${mainStyles.Colors.AltGreen};
    }
  `,
};
