import Form from './Form';
import FieldsArea from './FieldsArea';
import FormBlock from './FormBlock';
import FieldsSet from './FieldsSet';
import FieldsSetHorizontal from './FieldsSetHorizontal';
import FormCheckbox from './FormCheckbox';
import FormError from './FormError';
import FormField from './FormField';
import FormFile from './FormFile';
import TextArea from './TextArea';
import FormRadio from './FormRadio';
import FormRow from './FormRow';
import FormRowSections from './FormRowSections';
import ActionsRow from './ActionsRow';
import Thumb from './Thumb';
import RadioBtn from './RadioBtn';
import * as styles from './styles';
import Checkbox from './CheckboxWithRef';

export default {
  Form,
  FieldsArea,
  FormBlock,
  FieldsSet,
  FieldsSetHorizontal,
  Checkbox,
  FormCheckbox,
  FormError,
  FormField,
  TextArea,
  FormFile,
  FormRadio,
  FormRow,
  FormRowSections,
  ActionsRow,
  Thumb,
  RadioBtn,
  styles,
};
