/** @jsx jsx */
import { jsx } from '@emotion/core';
import _ from 'lodash';

import { Component } from 'react';
import TimelineValue from './TimelineValue';
import DropTarget from './dropTarget';

import * as styles from './styles';

export interface IProps {
  machinery: any;
  users: any;
  scheme: string[];
  maxVisibleCells?: number;
  limitRange: any;
  boxRange: any;
  isEditMode: boolean;
  onChange?: (id: any, range: any) => void;
  onClick?: (e: any) => void;
  connectDropTarget: any;
  isOverCurrent?: boolean;
}

export interface IState {}

jsx;
class Timeline extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  onClick = (e: any) => {
    const { onClick = _.identity, isEditMode } = this.props;
    if (isEditMode) {
      onClick(e);
    }
  };

  render() {
    const {
      users,
      scheme,
      boxRange,
      limitRange,
      isEditMode = false,
      onChange,
      maxVisibleCells = null,
      connectDropTarget,
      machinery,
      isOverCurrent = false,
    } = this.props;

    const countVisibleCells = maxVisibleCells
      ? scheme.length > maxVisibleCells
        ? maxVisibleCells
        : scheme.length
      : scheme.length;

    const minCellHeight = users.length * 20 + 20;

    return connectDropTarget(
      <div className="timeline">
        <div css={styles.timelineBox(isOverCurrent)}>
          <div css={styles.timelineGridBox(minCellHeight)}>
            {scheme.map((item, index) => {
              return (
                <div key={index} className="timeline-cell" css={styles.timelineGridCell(100 / countVisibleCells)} />
              );
            })}
          </div>
          <TimelineValue
            machinery={machinery}
            users={users}
            isEdit={isEditMode}
            onChange={onChange}
            boxRange={boxRange}
            limitRange={limitRange}
          />
        </div>
      </div>
    );
  }
}

export default DropTarget(Timeline);
