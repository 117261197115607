/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { withFormik, FormikProps } from 'formik';
import _ from 'lodash';
import * as yup from 'yup';
import { compose, lifecycle } from 'recompose';
import { connect } from '../../../../../utils/redux';
import PrimaryBtn from '../../../../common/buttons/PrimaryBtn';
import OutlineBtn from '../../../../common/buttons/OutlineBtn';
import form from '../../../../common/form';

import { Action } from '../../../../../store/types';
import { ViewModes } from './Item';
import { print, printInfo } from '../store/selectors';
import { getProductionPrintReq, getProductionPrintInfoReq, setProductionPrintInfoParams } from '../store/actions';

import modal from '../../../../common/modal';
import routes from '../../../../../routes';
import { stringToBoolean } from '../../../../../utils/string';
import AsyncData from '../../../../common/async/AsyncData';

interface FormValues {
  version: string;
  format: string;
  landscape: boolean;
  isFiltered: boolean;
  viewMode: ViewModes;
}

interface OtherProps {
  onPrint?: () => void;
  onClose: () => void;
  production?: any;
  getProductionPrintReq?: Action<{}>;
  getProductionPrintInfoReq?: Action<{}>;
  setProductionPrintInfoParams?: Action<{}>;
  print?: any;
  printInfo?: any;
  activitiesParams?: any;
}

jsx;

const withLifecycle = lifecycle<OtherProps, {}>({
  componentDidMount() {
    const { setProductionPrintInfoParams = _.identity, production = null } = this.props;
    setProductionPrintInfoParams({ ownerId: production.id });
  },
});

const FunctionForm = ({
  onClose,
  errors,
  touched,
  values,
  print,
  printInfo,
  setFieldValue,
  getProductionPrintInfoReq,
}: OtherProps & FormikProps<FormValues>) => {
  const { Modal, ModalHeader, ModalFooter, ModalBody, ModalActions } = modal;
  const { FormRow, FormField, FormError, Form, FieldsArea, FormRadio, FieldsSetHorizontal } = form;

  return (
    <div>
      <Modal isOpen={true}>
        <Form>
          <ModalHeader>Download schedule</ModalHeader>
          <AsyncData
            data={[
              {
                asyncData: printInfo,
                asyncGetAction: getProductionPrintInfoReq,
              },
              {
                asyncData: print,
              },
            ]}
          >
            {() => {
              return (
                <ModalBody>
                  <FieldsSetHorizontal>
                    <FieldsArea>
                      <FormRow>
                        <div role="group" css={style.column1}>
                          <label htmlFor="viewMode" css={style.label}>
                            Schedule
                          </label>
                          <FormRadio
                            name="viewMode"
                            value={ViewModes.activity}
                            values={values}
                            title="Production schedule"
                          />
                          <FormRadio
                            name="viewMode"
                            value={ViewModes.transport}
                            values={values}
                            title="Transport schedule"
                          />
                          <FormRadio
                            name="viewMode"
                            value={ViewModes.mixed}
                            values={values}
                            title="Both schedules in one schedule"
                          />
                        </div>
                        <FormError name="viewMode" errors={errors} touched={touched} />
                      </FormRow>
                    </FieldsArea>
                    <FormRow>
                      <div role="group" css={style.column2}>
                        <label htmlFor="isFiltered" css={style.label}>
                          Filters
                        </label>
                        <FormRadio
                          name="isFiltered"
                          value={true}
                          values={values}
                          title="Schedule as filtered"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const newValue = stringToBoolean(e.currentTarget.value);
                            setFieldValue('isFiltered', newValue);
                          }}
                        />
                        <FormRadio
                          name="isFiltered"
                          value={false}
                          values={values}
                          title="Full unfiltered schedule"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const newValue = stringToBoolean(e.currentTarget.value);
                            setFieldValue('isFiltered', newValue);
                          }}
                        />
                      </div>
                      <FormError name="isFiltered" errors={errors} touched={touched} />
                    </FormRow>
                  </FieldsSetHorizontal>
                  <FieldsSetHorizontal>
                    <FieldsArea>
                      <FormRow>
                        <div role="group" css={style.column2}>
                          <label htmlFor="format" css={style.label}>
                            Paper format
                          </label>
                          {printInfo.data.formats.toReversed().map((format: string) => (
                            <FormRadio name="format" value={format} values={values} title={format} key={format} />
                          ))}
                        </div>
                        <FormError name="format" errors={errors} touched={touched} />
                      </FormRow>
                      <FormRow>
                        <div css={style.columnVersion}>
                          <label htmlFor="version" css={[style.label, style.version]}>
                            Version
                          </label>
                          <FormField name="version" />
                          <FormError name="version" errors={errors} touched={touched} />
                        </div>
                      </FormRow>
                    </FieldsArea>
                    <FormRow>
                      <div role="group" css={style.column2}>
                        <label htmlFor="landscape" css={style.label}>
                          Paper orientation
                        </label>
                        <FormRadio
                          name="landscape"
                          value={true}
                          values={values}
                          title="Landscape view"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const newValue = stringToBoolean(e.currentTarget.value);
                            setFieldValue('landscape', newValue);
                          }}
                        />
                        <FormRadio
                          name="landscape"
                          value={false}
                          values={values}
                          title="Portrait view"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const newValue = stringToBoolean(e.currentTarget.value);
                            setFieldValue('landscape', newValue);
                          }}
                        />
                      </div>
                      <FormError name="landscape" errors={errors} touched={touched} />
                    </FormRow>
                  </FieldsSetHorizontal>
                </ModalBody>
              );
            }}
          </AsyncData>

          <ModalFooter>
            <ModalActions>
              <OutlineBtn type="button" onClick={onClose} title="Cancel" />
              <PrimaryBtn title="Download" type="submit" />
            </ModalActions>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

const functionSchema = yup.object({}).shape({
  version: yup.string().required('Required'),
  format: yup.string().required('Required'),
});

export const exchangeFormic = withFormik<OtherProps, FormValues>({
  enableReinitialize: true,

  mapPropsToValues: ({ printInfo: { data } }) => {
    const initFormat = data ? data.formats[data.formats.length - 1] : 'A4';

    return {
      version: data ? data.currentVersion : '',
      format: initFormat,
      landscape: true,
      isFiltered: false,
      viewMode: ViewModes.activity,
    };
  },

  validationSchema: functionSchema,

  handleSubmit: (
    values,
    { props: { getProductionPrintReq = _.identity, production, activitiesParams, onClose }, setSubmitting }
  ) => {
    const { format, landscape, version, isFiltered, viewMode } = values;
    const scale = format === 'A4' && landscape === false ? 0.7 : 1;
    const filters: [string, any][] = Object.entries(activitiesParams).filter(
      (param) => param[0].includes('filter') && param[1]
    );
    const filteredParams = filters.reduce((acc, param) => {
      if (param[1].length > 1) {
        let params = '';
        param[1].forEach((id: number) => {
          params = `${params}&${param[0]}=${id}`;
        });
        return `${acc}${params}`;
      } else if (param[1].length === 1) {
        return `${acc}&${param[0]}=${param[1]}`;
      } else if (param[1] === true) {
        return `${acc}&${param[0]}=true`;
      }
      return acc;
    }, '');
    const filterParams = isFiltered ? filteredParams : '';
    const filename = `${production.code} Production Schedule ${version}.pdf`;

    getProductionPrintReq({
      format,
      landscape,
      version,
      scale,
      page: routes.ProductionPrint.replace(':id/print', `${production.id}/print?mode=${viewMode}${filterParams}`),
      productionId: production.id,
      margin: {},
      viewMode,
      filterParams,
      filename,
    });
    onClose();
  },
  displayName: 'AddFunctionForm',
});

export default compose<OtherProps & FormikProps<FormValues>, OtherProps>(
  connect(
    { print, printInfo },
    { getProductionPrintReq, getProductionPrintInfoReq, setProductionPrintInfoParams }
  ),
  withLifecycle,
  exchangeFormic
)(FunctionForm);

const style = {
  column1: css`
    display: flex;
    flex-direction: column;
    min-width: 250px;
    padding-bottom: 30px;
  `,
  column2: css`
    display: flex;
    flex-direction: column;
    min-width: 210px;
    padding-bottom: 30px;
  `,
  label: css`
    font-weight: 700 !important;
    padding-bottom: 15px !important;
  `,
  columnVersion: css`
    width: 70%;
    padding-bottom: 15px;
  `,
  version: css`
    margin-top: -45px !important;
  `,
};
