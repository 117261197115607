/** @jsx jsx */
import { Component } from 'react';
import { jsx, css } from '@emotion/core';
import { uniq } from 'lodash';
// components
import MultiSelectInput from '../../../../common/form/multiselect/MultiselectModal';
// utils
import _ from 'lodash';
import { toast } from 'react-toastify';
import withAcl, { IWrappedProps } from '../../withAcl';
import withVisualContext from '../../../../../contexts/withVisualContext';
import { IVisualContext } from '../../../../../contexts/VisualContext';
// types
import { Action } from '../../../../../store/types';
import { SupplierFunction } from '../../SupplierFunctions/store/types';
import { ISupplier } from '../../Suppliers/store/types';
import { IProduction } from '../store/types';
import { ViewModes } from './Item';

interface IProps extends IWrappedProps {
  activities: any;
  supplierFunctions: SupplierFunction[];
  suppliers: ISupplier[];
  productionId: number;
  isMainProduction: boolean;
  subproductions?: IProduction[];
  subSubproductions?: IProduction[];
  onChangeParams: Action<{}>;
  gates: any[] | null;
  visual: IVisualContext;

  allSuppliers?: ISupplier[];
  allSupplierFunctions?: SupplierFunction[];
  allSubproductions?: IProduction[];
  allSubSubproductions?: IProduction[];
  viewMode: ViewModes;
}

interface IState {
  functions: SupplierFunction[];
  suppliers: ISupplier[];
  subproductions?: IProduction[];
  subSubproductions?: IProduction[];
  gates?: any[] | null;

  selectedFunction: number[];
  selectedSupplier: number[];
  selectedSubproduction: number[];
  selectedSubSubproduction: number[];
  selectedGates: number[];

  submitedFunction: number[];
  submitedSupplier: number[];
  submitedSubproduction: number[];
  submitedSubSubproduction: number[];
  submitedGates: number[];

  isFunctionSubmited: boolean;
  isSupplierSubmited: boolean;
  isSubproductionSubmited: boolean;
  isSubSubproductionSubmited: boolean;
  isGatesSubmited: boolean;
}

jsx;
class ActivityFilters extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      functions: this.props.supplierFunctions,
      suppliers: this.props.suppliers,
      subproductions: this.props.subproductions,
      subSubproductions: this.props.subSubproductions,
      gates: null,

      selectedFunction: [],
      selectedSupplier: [],
      selectedSubproduction: [],
      selectedSubSubproduction: [],
      selectedGates: [],

      submitedFunction: [],
      submitedSupplier: [],
      submitedSubproduction: [],
      submitedSubSubproduction: [],
      submitedGates: [],

      isFunctionSubmited: false,
      isSupplierSubmited: false,
      isSubproductionSubmited: false,
      isSubSubproductionSubmited: false,
      isGatesSubmited: false,
    };
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>): void {
    const {
      gates,
      viewMode,
      productionId,
      supplierFunctions,
      suppliers,
      subproductions,
      subSubproductions,
    } = this.props;
    if (
      prevProps.supplierFunctions !== supplierFunctions ||
      prevProps.suppliers !== suppliers ||
      prevProps.subproductions !== subproductions ||
      prevProps.subSubproductions !== subSubproductions
    ) {
      this.setState({
        functions: this.props.supplierFunctions,
        suppliers: this.props.suppliers,
        subproductions: this.props.subproductions,
        subSubproductions: this.props.subSubproductions,
      });
    }

    if (prevProps.productionId !== productionId) {
      this.setState({
        functions: this.props.supplierFunctions,
        suppliers: this.props.suppliers,
        subproductions: this.props.subproductions,
        subSubproductions: this.props.subSubproductions,

        selectedFunction: [],
        selectedSupplier: [],
        selectedSubproduction: [],
        selectedSubSubproduction: [],
        selectedGates: [],

        submitedFunction: [],
        submitedSupplier: [],
        submitedSubproduction: [],
        submitedSubSubproduction: [],
        submitedGates: [],

        isFunctionSubmited: false,
        isSupplierSubmited: false,
        isSubproductionSubmited: false,
        isSubSubproductionSubmited: false,
        isGatesSubmited: false,
      });
    }
    if (prevProps.gates !== gates) {
      if (
        !prevState.gates ||
        (prevState.gates && prevState.gates.length < 1) ||
        (prevState.gates && gates && prevState.gates.length < gates.length)
      ) {
        this.setState({ gates });
      }
    }
    if (viewMode === ViewModes.activity && prevProps.viewMode !== ViewModes.activity) {
      this.setState({ selectedGates: [], submitedGates: [], isGatesSubmited: false });
    }
  }
  componentWillUnmount() {
    this.setState({
      functions: [],
      suppliers: [],
      subproductions: [],
      subSubproductions: [],
      gates: null,

      selectedFunction: [],
      selectedSupplier: [],
      selectedSubproduction: [],
      selectedSubSubproduction: [],
      selectedGates: [],

      submitedFunction: [],
      submitedSupplier: [],
      submitedSubproduction: [],
      submitedSubSubproduction: [],
      submitedGates: [],

      isFunctionSubmited: false,
      isSupplierSubmited: false,
      isSubproductionSubmited: false,
      isSubSubproductionSubmited: false,
      isGatesSubmited: false,
    });
  }

  onFunctionsChange = (value: number[]) => {
    this.setState({
      selectedFunction: value,
      isFunctionSubmited: false,
    });
  };
  submitFunctionsChange = () => {
    const { selectedFunction: value } = this.state;
    const { onChangeParams } = this.props;

    if (value.length) {
      if (value.includes(0)) {
        const values = _.compact(value);

        onChangeParams({
          'filter.functions.id': values.length > 0 ? values : null,
          'filter.activity.allFunctions': true,
        });
      } else {
        onChangeParams({ 'filter.functions.id': value, 'filter.activity.allFunctions': null });
      }
      this.setState({ isFunctionSubmited: true, submitedFunction: value });
    } else {
      onChangeParams({ 'filter.functions.id': null, 'filter.activity.allFunctions': null });
      this.setState({ isFunctionSubmited: true, submitedFunction: [] });
    }
  };

  onSuppliersChange = (value: number[]) => {
    this.setState({
      selectedSupplier: value,
      isSupplierSubmited: false,
    });
  };

  submitSuppliersChange = () => {
    const { selectedSupplier: value } = this.state;
    const { onChangeParams } = this.props;

    if (value.length) {
      if (value.includes(0)) {
        const values = _.compact(value);

        onChangeParams({
          'filter.supplier.id': values.length > 0 ? values : null,
          'filter.activity.allSuppliers': true,
        });
      } else {
        onChangeParams({ 'filter.supplier.id': value, 'filter.activity.allSuppliers': null });
      }
      this.setState({ isSupplierSubmited: true, submitedSupplier: value });
    } else {
      onChangeParams({ 'filter.supplier.id': null, 'filter.activity.allSuppliers': null });
      this.setState({ isSupplierSubmited: true, submitedSupplier: [] });
    }
  };

  onSubproductionsChange = (value: number[]) => {
    const lastValue = _.last(value);
    const isAll = lastValue === 0;
    const stateValue = isAll ? [0] : _.compact(value);

    this.setState({
      selectedSubproduction: stateValue,
      isSubproductionSubmited: false,
    });
  };
  submitSubproductionsChange = () => {
    const { productionId, onChangeParams, subproductions } = this.props;
    const { selectedSubproduction: value, selectedSubSubproduction: subSubValue } = this.state;

    const underlyingSubSub =
      subproductions &&
      subproductions.reduce((acc: number[], item: IProduction) => {
        if (value && value.includes(item.id)) {
          let includedSubSubIds: number[];
          if (item.sub && item.sub.length > 0) {
            includedSubSubIds = item.sub.map((subSub) => subSub.id);
            return [...acc, ...includedSubSubIds];
          }
        }
        return acc;
      }, []);

    const subSubNewValue = underlyingSubSub ? [...subSubValue, ...underlyingSubSub] : subSubValue;

    const changedValue = value.length === 1 && value[0] === 0 ? [productionId] : value;

    const combinedSubFilters = [...changedValue, ...subSubNewValue];

    onChangeParams({ 'filter.production.id': combinedSubFilters });

    this.setState({
      isSubproductionSubmited: true,
      submitedSubproduction: value,

      submitedSubSubproduction: subSubNewValue,
      // selectedSubSubproduction: subSubNewValue,
    });
  };
  onSubSubproductionsChange = (value: number[]) => {
    if (value && value.length > 0) {
      this.setState({
        selectedSubSubproduction: value,
        isSubSubproductionSubmited: false,
      });
    } else {
      this.setState({
        selectedSubSubproduction: value,
        isSubSubproductionSubmited: true,
      });
    }
  };
  submitSubSubproductionsChange = () => {
    const { productionId, onChangeParams } = this.props;
    const { selectedSubSubproduction: value, selectedSubproduction: subValue } = this.state;

    const changedValue = subValue.length === 1 && subValue[0] === 0 ? [productionId] : subValue;
    const combinedSubFilters = uniq([...changedValue, ...value]);

    onChangeParams({ 'filter.production.id': combinedSubFilters });
    this.setState({ isSubSubproductionSubmited: true, submitedSubSubproduction: value });
  };

  onGatesChange = (value: number[]) => {
    this.setState({
      selectedGates: value,
      isGatesSubmited: false,
    });
  };

  submitGatesChange = () => {
    const { onChangeParams } = this.props;
    const { selectedGates: value } = this.state;
    onChangeParams({ 'filter.gate.id': value });
    this.setState({ isGatesSubmited: true, submitedGates: value });
  };

  render() {
    const { test, visual, isMainProduction, viewMode } = this.props;
    const {
      selectedFunction,
      selectedSupplier,
      selectedSubproduction,
      selectedSubSubproduction,
      selectedGates,
      functions,
      suppliers,
      subproductions,
      subSubproductions,
      gates,
    } = this.state;

    return (
      <div css={style.wrapper}>
        <div>Filtered by</div>

        <div css={style.filter}>
          <MultiSelectInput
            items={[{ id: 0, name: `All production ${visual.labels.functions}` }, ...functions]}
            noSelectedMsg={`Choose ${visual.labels.functions}`}
            selected={selectedFunction}
            scheme={{ id: 'id', title: 'name' }}
            onChange={this.onFunctionsChange}
            handleSubmit={this.submitFunctionsChange}
            onBlur={() => {
              setTimeout(() => {
                this.setState((prev) => {
                  if (!prev.isFunctionSubmited) {
                    prev.selectedFunction.length > 0 &&
                      toast.warn('Please confirm your choice by pressing "Apply" button');
                    return { isFunctionSubmited: false, selectedFunction: prev.submitedFunction };
                  } else {
                    return { isFunctionSubmited: true, selectedFunction };
                  }
                });
              }, 200);
            }}
          />
        </div>

        {test('supplier', 'R') && (
          <div css={style.filter}>
            <MultiSelectInput
              items={[{ id: 0, name: 'All production suppliers' }, ...suppliers]}
              noSelectedMsg="Choose suppliers"
              selected={selectedSupplier}
              scheme={{ id: 'id', title: 'name' }}
              onChange={this.onSuppliersChange}
              handleSubmit={this.submitSuppliersChange}
              onBlur={() => {
                setTimeout(() => {
                  this.setState((prev) => {
                    if (!prev.isSupplierSubmited) {
                      prev.selectedSupplier.length > 0 &&
                        toast.warn('Please confirm your choice by pressing "Apply" button');
                      return {
                        isSupplierSubmited: false,
                        selectedSupplier: prev.submitedSupplier,
                      };
                    } else {
                      return { isSupplierSubmited: true, selectedSupplier };
                    }
                  });
                }, 200);
              }}
            />
          </div>
        )}

        {subproductions && !!subproductions.length && (
          <div css={style.filter}>
            <MultiSelectInput
              items={isMainProduction ? [{ id: 0, name: 'Only main production' }, ...subproductions] : subproductions}
              noSelectedMsg={
                isMainProduction ? `Choose ${visual.labels.subproduction}` : `Choose ${visual.labels.subSubproduction}`
              }
              selected={selectedSubproduction}
              selectAllBtn={false}
              scheme={{ id: 'id', title: 'name' }}
              onChange={this.onSubproductionsChange}
              handleSubmit={this.submitSubproductionsChange}
              onBlur={() => {
                setTimeout(() => {
                  this.setState((prev) => {
                    if (!prev.isSubproductionSubmited) {
                      prev.selectedSubproduction.length > 0 &&
                        toast.warn('Please confirm your choice by pressing "Apply" button');
                      return { isSubproductionSubmited: false, selectedSubproduction: prev.submitedSubproduction };
                    } else {
                      return { isSubproductionSubmited: true, selectedSubproduction };
                    }
                  });
                }, 200);
              }}
            />
          </div>
        )}
        {subSubproductions && !!subSubproductions.length && (
          <div css={style.filter}>
            <MultiSelectInput
              items={subSubproductions}
              noSelectedMsg={`Choose ${visual.labels.subSubproduction}`}
              selected={selectedSubSubproduction}
              selectAllBtn={false}
              scheme={{ id: 'id', title: 'name' }}
              onChange={this.onSubSubproductionsChange}
              handleSubmit={this.submitSubSubproductionsChange}
              onBlur={() => {
                setTimeout(() => {
                  this.setState((prev) => {
                    if (!prev.isSubSubproductionSubmited) {
                      prev.selectedSubSubproduction.length > 0 &&
                        toast.warn('Please confirm your choice by pressing "Apply" button');
                      return {
                        isSubSubproductionSubmited: false,
                        selectedSubSubproduction: prev.submitedSubSubproduction,
                      };
                    } else {
                      return { isSubSubproductionSubmited: true, selectedSubSubproduction };
                    }
                  });
                }, 200);
              }}
            />
          </div>
        )}
        {gates && gates.length > 0 && (ViewModes.transport === viewMode || ViewModes.mixed === viewMode) && (
          <div css={style.filter}>
            <MultiSelectInput
              items={gates}
              noSelectedMsg="Choose gates"
              selected={selectedGates}
              selectAllBtn={false}
              scheme={{ id: 'id', title: 'name' }}
              onChange={this.onGatesChange}
              handleSubmit={this.submitGatesChange}
              onBlur={() => {
                setTimeout(() => {
                  this.setState((prev) => {
                    if (!prev.isGatesSubmited) {
                      prev.selectedGates.length > 0 &&
                        toast.warn('Please confirm your choice by pressing "Apply" button');
                      return { isGatesSubmited: false, selectedGates: prev.submitedGates };
                    } else {
                      return { isGatesSubmited: true, selectedGates };
                    }
                  });
                }, 200);
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withAcl(withVisualContext(ActivityFilters));

const style = {
  wrapper: css`
    margin: 20px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  filter: css`
    display: flex;
    margin-left: 20px;
    min-width: 180px;
  `,
  option: css`
    position: relative;
    border-bottom: 1px solid #e6e6e6;
  `,
};
