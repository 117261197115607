/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Fragment } from 'react';
import { withFormik, FormikProps } from 'formik';
import * as yup from 'yup';
import { compose, lifecycle } from 'recompose';
import { RouteComponentProps } from 'react-router';
// redux
import { connect } from '../../../../utils/redux';
import { updateSubproductionReq } from './store/actions';
import { updateProductionReq } from './store/actions';
import { getClientsForSelectReq, refreshClientForSelect } from '../Clients/store/actions';
import { clientsForSelect } from '../Clients/store/selectors';
import { getClientAgentsReq, setClientAgentsParams } from '../Clients/Agents/store/actions';
import { clientAgents } from '../Clients/Agents/store/selectors';
import { getProducersReq } from '../Producers/store/actions';
import { producers } from '../Producers/store/selectors';
// components
import AsyncData from '../../../common/async/AsyncData';
import { Link } from 'react-router-dom';
import PrimaryBtn from '../../../common/buttons/PrimaryBtn';
import OutlineBtn from '../../../common/buttons/OutlineBtn';
import Checkbox from '../../../common/form/InputCheckbox';
import form from '../../../common/form';
import select from '../../../common/form/select';
import page from '../../../common/page';
import DateRange, { IDataRangeValue } from '../../../common/form/datepicker/DateRange';
import { IDataRangeWithName } from '../../../common/form/datepicker/DateRangeWithName';
import DateRangeListWithName from '../../../common/form/datepicker/DateRangeListWithName';
import MultiSelectArea from '../../../common/form/multiselect/MultiSelectArea';
// types
import { Action } from '../../../../store/types';
import { ActivityTypes, IProduction } from './store/types';
import { ClientListState } from '../Clients/store/types';
import { ClientAgentListState } from '../Clients/Agents/store/types';
import { IProducer } from '../Producers/store/types';
// other
import routes from '../../../../routes';
import * as validatinShemas from '../../../../utils/validatinShemas';
// utils
import _ from 'lodash';
import withVisualContext from '../../../../contexts/withVisualContext';
import { IVisualContext } from '../../../../contexts/VisualContext';
import { extractKeyValue } from '../../../../utils/collection';

interface FormValues {
  id: number;
  name: string;
  productionName: string;
  code: string;
  location: string;
  client: number;
  clientAgents: number[];
  producer: number;
  freelancers: number[];
  loadIn: IDataRangeValue;
  loadOut: IDataRangeValue;
  shows: IDataRangeWithName[];
  rehearsals: IDataRangeWithName[];
  hideShows: boolean;
  showTransport: boolean;
  showMachinery: boolean;
}

interface MatchParams {
  production_id?: string;
  subproduction_id?: string;
}

interface FormProps {}

interface OtherProps extends RouteComponentProps<MatchParams> {
  updateSubproductionReq: Action<{}>;
  updateProductionReq: Action<{}>;
  getClientsForSelectReq: Action<{}>;
  refreshClientForSelect: Action<{}>;
  clientsForSelect: ClientListState;
  setClientAgentsParams: Action<{}>;
  getClientAgentsReq: Action<{}>;
  allClientAgents: ClientAgentListState;
  getProducersReq: Action<{}>;
  producers: IProducer[];
  subproduction: IProduction;
  visual: IVisualContext;
}

jsx;
const withLifecycle = lifecycle<OtherProps, {}>({
  componentDidMount() {
    const {
      getProducersReq,
      subproduction,
      getClientsForSelectReq,
      setClientAgentsParams,
      refreshClientForSelect,
    } = this.props;
    refreshClientForSelect();
    getClientsForSelectReq({ size: 1000 });
    setClientAgentsParams({ ownerId: subproduction.client.id, size: 1000 });
    getProducersReq({ size: 1000 });
  },
});

const prepareProductionValues = (values: FormValues) => {
  const clearEmpty = (item: IDataRangeWithName) => item.name || item.range.from || item.range.to;
  return {
    ...values,
    shows: values.shows.filter(clearEmpty),
    rehearsals: values.rehearsals.filter(clearEmpty),
  };
};

const CreateForm = ({
  errors,
  touched,
  values,
  setFieldValue,
  getClientsForSelectReq,
  clientsForSelect,
  getClientAgentsReq,
  allClientAgents,
  producers,
  subproduction,
  visual,
}: OtherProps & FormikProps<FormValues>) => {
  const { FormRow, FormRowSections, FormField, FormError, FormBlock, FieldsSet, ActionsRow, Form, FieldsArea } = form;
  const { Select, Option } = select;
  const { Page, Breadcrumbs, PageHeader } = page;

  const prodId = subproduction.main.id;
  const subProdId = subproduction.id;
  const formTitle = `Update ${subproduction.canHaveSub ? visual.labels.subproduction : visual.labels.subSubproduction}`;

  return (
    <Fragment>
      <PageHeader title={formTitle}>
        <Breadcrumbs
          path={[
            { title: 'Productions', link: routes.Productions },
            {
              title: subproduction.name,
              link: routes.Production.replace(':id', `${subProdId}`),
            },
            {
              title: formTitle,
              link: routes.SubProductionsEdit.replace(':production_id', `${prodId}`).replace(
                ':subproduction_id',
                `${subProdId}`
              ),
            },
          ]}
        />
      </PageHeader>
      <Page>
        <Form>
          <div>
            <AsyncData
              data={[
                { asyncData: clientsForSelect, asyncGetAction: getClientsForSelectReq },
                { asyncData: allClientAgents, asyncGetAction: getClientAgentsReq },
              ]}
            >
              {() => {
                return (
                  <FieldsArea>
                    {/* MAIN */}
                    <FormBlock>
                      <FieldsSet width={2}>
                        <FormRow>
                          <FormRowSections width={2}>
                            <label htmlFor="productionName">Production</label>
                            <FormField name="productionName" value={values.productionName} disabled={true} />
                            <FormError name="productionName" errors={errors} touched={touched} />
                          </FormRowSections>

                          <FormRowSections width={2}>
                            <label htmlFor="name">
                              {subproduction.canHaveSub ? visual.labels.Subproduction : visual.labels.SubSubproduction}
                            </label>
                            <FormField name="name" />
                            <FormError name="name" errors={errors} touched={touched} />
                          </FormRowSections>
                        </FormRow>
                        <FormRow>
                          <FormRowSections width={2}>
                            <label htmlFor="code">Syntax</label>
                            <FormField name="code" placeholder="syntax" />
                            <FormError name="code" errors={errors} touched={touched} />
                          </FormRowSections>

                          <FormRowSections width={2}>
                            <label htmlFor="location">Location</label>
                            <FormField name="location" placeholder="location" />
                            <FormError name="location" errors={errors} touched={touched} />
                          </FormRowSections>
                        </FormRow>

                        <FormRow>
                          <FormRowSections width={2}>
                            <label htmlFor="client">Client</label>
                            <Select
                              disabled={true}
                              scheme={{ id: 'id', title: 'name' }}
                              items={clientsForSelect.data}
                              selected={values.client}
                            >
                              {(item: any, selected: boolean) => {
                                return <Option title={item.name} selected={selected} />;
                              }}
                            </Select>
                            <FormError name="client" errors={errors} touched={touched} />
                          </FormRowSections>

                          <FormRowSections width={2}>
                            <label htmlFor="clientAgents">Client agent</label>
                            <MultiSelectArea
                              scheme={{ id: 'id', title: 'name' }}
                              items={allClientAgents.data}
                              selected={values.clientAgents}
                              onChange={(value) => {
                                setFieldValue('clientAgents', value);
                              }}
                            >
                              {(item: any, selected: boolean) => {
                                return <Option title={item.name} selected={selected} />;
                              }}
                            </MultiSelectArea>
                            <FormError name="clientAgents" errors={errors} touched={touched} />
                          </FormRowSections>
                        </FormRow>

                        <FormRow>
                          <FormRowSections width={2}>
                            <label htmlFor="producer">Production manager</label>
                            <Select
                              scheme={{ id: 'id', title: 'name' }}
                              items={producers.filter((item) => item.isActive)}
                              selected={values.producer}
                              onChange={(value) => {
                                setFieldValue('producer', value);
                              }}
                            >
                              {(item: any, selected: boolean) => {
                                return <Option title={item.name} selected={selected} />;
                              }}
                            </Select>
                            <FormError name="producer" errors={errors} touched={touched} />
                          </FormRowSections>
                          <FormRowSections width={2}>
                            <label htmlFor="freelancers">Freelancers</label>
                            <MultiSelectArea
                              scheme={{ id: 'id', title: 'name' }}
                              items={producers.filter((item) => item.isActive && item.isFreelancer)}
                              selected={values.freelancers}
                              onChange={(value) => {
                                setFieldValue('freelancers', value);
                              }}
                            >
                              {(item: any, selected: boolean) => {
                                return <Option title={item.name} selected={selected} />;
                              }}
                            </MultiSelectArea>
                            <FormError name="freelancers" errors={errors} touched={touched} />
                          </FormRowSections>
                        </FormRow>
                      </FieldsSet>
                    </FormBlock>

                    {/* LOAD DATES */}
                    <FormBlock>
                      <FieldsSet width={2}>
                        <FormRow>
                          <DateRange
                            value={values.loadIn}
                            fromLabel={'Load in from'}
                            toLabel={'Load in to'}
                            onChange={(value) => {
                              setFieldValue('loadIn', value);
                            }}
                          />
                        </FormRow>
                        <FormError name="loadIn" errors={errors} touched={touched} />

                        <FormRow>
                          <DateRange
                            value={values.loadOut}
                            fromLabel={'Load out from'}
                            toLabel={'Load out to'}
                            onChange={(value) => {
                              setFieldValue('loadOut', value);
                            }}
                          />
                        </FormRow>
                        <FormError name="loadIn" errors={errors} touched={touched} />
                      </FieldsSet>
                    </FormBlock>

                    {/* SHOWS DATES */}
                    <FormBlock>
                      <FieldsSet width={2}>
                        <DateRangeListWithName
                          nameLabel="Shows name"
                          fromLabel="Show start"
                          toLabel="Show end"
                          value={values.shows}
                          error={<FormError name="shows" errors={errors} touched={touched} />}
                          btn={<OutlineBtn title={'Add new show'} />}
                          onChange={(value) => {
                            setFieldValue('shows', value);
                          }}
                          checkboxForHiding="Hide in the schedule"
                          hidedShow={values.hideShows}
                          onHideShow={(e: any) => {
                            setFieldValue('hideShows', e.currentTarget.checked);
                          }}
                        />
                      </FieldsSet>
                    </FormBlock>

                    {/* REHEARSALS DATES */}
                    <FormBlock>
                      <FieldsSet width={2}>
                        <DateRangeListWithName
                          nameLabel="Rehearsals name"
                          fromLabel="Rehearsals start"
                          toLabel="Rehearsals end"
                          value={values.rehearsals}
                          error={<FormError name="rehearsals" errors={errors} touched={touched} />}
                          btn={<OutlineBtn title={'Add new rehearsal'} />}
                          onChange={(value) => {
                            setFieldValue('rehearsals', value);
                          }}
                        />
                      </FieldsSet>
                    </FormBlock>

                    <FormBlock>
                      <FieldsSet width={1}>
                        <FormRow>
                          <label css={checkboxLabel}>
                            <Checkbox
                              name="showTransport"
                              checked={values.showTransport}
                              onChange={(e: any) => {
                                setFieldValue('showTransport', e.currentTarget.checked);
                              }}
                            />{' '}
                            Show Transport schedule
                          </label>
                          <label css={checkboxLabel}>
                            <Checkbox
                              name="showMachinery"
                              checked={values.showMachinery}
                              onChange={(e: any) => {
                                setFieldValue('showMachinery', e.currentTarget.checked);
                              }}
                            />{' '}
                            Show Machinery schedule
                          </label>
                        </FormRow>
                      </FieldsSet>
                    </FormBlock>
                  </FieldsArea>
                );
              }}
            </AsyncData>

            <ActionsRow>
              <Link to={routes.Production.replace(':id', `${subproduction.id}`)}>
                <OutlineBtn title="Cancel" isWide={false} />
              </Link>
              <PrimaryBtn title={formTitle} isWide={false} />
            </ActionsRow>
          </div>
        </Form>
      </Page>
    </Fragment>
  );
};

export const exchangeFormic = withFormik<FormProps & OtherProps, FormValues>({
  enableReinitialize: true,
  validationSchema: yup.object({}).shape({
    clientAgents: validatinShemas.vIdListReq,
    producer: validatinShemas.vIdReq,
    client: yup.string().required(),
    location: yup.string().required(),
    code: yup.string().required('syntax is a required field'),
  }),
  mapPropsToValues: ({ subproduction }) => {
    const extractDTWithName = (item: any) => ({
      id: item.id,
      name: item.name || '',
      range: { from: item.range.from || '', to: item.range.to || '' },
    });
    const shows = subproduction.mainActivities
      .filter((item: any) => item.type === ActivityTypes.SHOW)
      .map(extractDTWithName);
    const rehearsals = subproduction.mainActivities
      .filter((item: any) => item.type === ActivityTypes.REHEARSAL)
      .map(extractDTWithName);

    const xxx = {
      id: _.get(subproduction, 'id', 0),
      name: _.get(subproduction, 'name', ''),
      main: _.get(subproduction, 'main.id', 0),
      productionName: _.get(subproduction, 'main.name', ''),
      location: _.get(subproduction, 'location', ''),
      code: _.get(subproduction, 'code', ''),
      client: _.get(subproduction, 'client.id', ''),
      clientAgents: extractKeyValue(subproduction.clientAgents, 'id'),
      producer: _.get(subproduction, 'producer.id', ''),
      freelancers: extractKeyValue(subproduction.freelancers, 'id'),
      loadIn: { from: _.get(subproduction, 'loadIn.from', ''), to: _.get(subproduction, 'loadIn.to', '') },
      loadOut: { from: _.get(subproduction, 'loadOut.from', ''), to: _.get(subproduction, 'loadOut.to', '') },
      shows: shows,
      rehearsals: rehearsals,
      hideShows: _.get(subproduction, 'hideShows', true),
      showTransport: subproduction.showTransport || false,
      showMachinery: subproduction.showMachinery || false,
    };

    return xxx;
  },
  handleSubmit: (values, { props: { updateProductionReq }, setSubmitting }) => {
    // handleSubmit: (values, { props, setSubmitting }) => {
    //   props.updateSubproductionReq({
    //     productionId: props.subproduction.main.id,
    //     values: prepareProductionValues(values),
    //   });
    updateProductionReq({ ...prepareProductionValues(values) });
  },
  displayName: 'EditSubProductionForm',
});

export default compose<OtherProps & FormikProps<FormValues>, {}>(
  connect(
    { clientsForSelect, allClientAgents: clientAgents, producers },
    {
      updateSubproductionReq,
      updateProductionReq,
      getClientsForSelectReq,
      refreshClientForSelect,
      getClientAgentsReq,
      setClientAgentsParams,
      getProducersReq,
    }
  ),
  withVisualContext,
  withLifecycle,
  exchangeFormic
)(CreateForm);

const checkboxLabel = css({
  cursor: 'pointer',
  marginTop: '12px',
  display: 'flex !important',
  alignItems: 'center  !important',
  columnGap: '10px',
});
