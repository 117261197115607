/** @jsx jsx */
import { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { jsx, css } from '@emotion/core';
// redux
import { connect } from '../../../../../utils/redux';
import { Action } from '../../../../../store/types';

import { production, productionLoading } from '../store/selectors';
import { getProductionReq, setProduction } from '../store/actions';
import { IProduction } from '../store/types';

import { client } from '../../Clients/store/selectors';
import { IClient } from '../../Clients/store/types';

import { producer } from '../../Producers/store/selectors';
import { IProducer } from '../../Producers/store/types';

import { allActivities } from './store/selectors';
import { getAllActivitiesReq, setAllActivitiesParams } from './store/actions';

import { getSupplierFunctionsReq } from '../../SupplierFunctions/store/actions';
import { supplierFunctions } from '../../SupplierFunctions/store/selectors';

import { getSuppliersForSelectReq } from '../../Suppliers/store/actions';
import { suppliersForSelect } from '../../Suppliers/store/selectors';

import { ActivitiesListState } from './store/types';

import { getProductionsReq, updateProductionDaysReq } from '../store/actions';
import { productions } from '../store/selectors';
import { ProductionListState } from '../store/types';

import AsyncData from '../../../../common/async/AsyncData';
import DateListPrint from './DateList/DateListPrint';
import { ViewModes, isViewMode } from './Item';
import _ from 'lodash';

import { SupplierFunctionListState } from '../../SupplierFunctions/store/types';
import { SupplierListState } from '../../Suppliers/store/types';

import * as mainStyles from '../../../../../styles/styles';

interface MatchParams {
  id?: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
  getProductionReq: Action<{}>;
  setProduction: Action<{}>;
  production: IProduction;
  productionLoading: Boolean;
  client: IClient;
  producer: IProducer;
  setAllActivitiesParams: Action<{}>;
  getAllActivitiesReq: Action<{}>;
  allActivities: ActivitiesListState;
  getSupplierFunctionsReq: Action<{}>;
  supplierFunctions: SupplierFunctionListState;
  getSuppliersForSelectReq: Action<{}>;
  suppliersForSelect: SupplierListState;
  cloneProductionReq: Action<{}>;
  productions: ProductionListState;
  getProductionsReq: Action<{}>;
  updateProductionDaysReq: Action<{}>;
}

interface IState {
  cloneProductionModalOpen: boolean;
  viewMode: ViewModes;
  suppliers: string[];
  allSuppliers: boolean | null;
  functions: string[];
  allFunctions: boolean | null;
  gate: string[] | null;
  productionsInFilter: string[];
}

jsx;
class Item extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const {
      getProductionReq,
      setProduction,
      match: { params },
    } = props;
    setProduction(null);
    getProductionReq(params.id, { fetchRelatedData: true });
    this.state = {
      cloneProductionModalOpen: false,
      viewMode: ViewModes.mixed,
      suppliers: [],
      allSuppliers: null,
      functions: [],
      allFunctions: null,
      gate: null,
      productionsInFilter: [],
    };
  }
  componentDidMount = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const mode = urlParams.get('mode');
    const suppliers = urlParams.getAll('filter.supplier.id');
    const allSuppliers = urlParams.get('filter.activity.allSuppliers');
    const functions = urlParams.getAll('filter.functions.id');
    const allFunctions = urlParams.get('filter.activity.allFunctions');
    const gate = urlParams.getAll('filter.gate.id');
    const productionsInFilter = urlParams.getAll('filter.production.id');

    if (isViewMode(mode)) {
      this.setState({ viewMode: mode });
    }
    if (suppliers && suppliers.length > 0) {
      this.setState({ suppliers: suppliers });
    }
    if (!!allSuppliers) {
      this.setState({ allSuppliers: true });
    }
    if (functions && functions.length > 0) {
      this.setState({ functions: functions });
    }
    if (productionsInFilter && productionsInFilter.length > 0) {
      this.setState({ productionsInFilter: productionsInFilter });
    }
    if (!!allFunctions) {
      this.setState({ allFunctions: true });
    }
    if (gate && gate.length > 0) {
      this.setState({ gate: gate });
    }
  };

  componentWillReceiveProps(nextProps: IProps) {
    const {
      getProductionReq,
      production,
      productionLoading,
      match: { params },
    } = nextProps;

    const isCurrentProduction = production && _.get(production, 'id') === Number(params.id);
    if (!isCurrentProduction && !productionLoading) {
      getProductionReq(params.id, { fetchRelatedData: true });
    }
  }

  render() {
    const {
      production,
      client,
      producer,
      allActivities,
      getAllActivitiesReq,
      supplierFunctions,
      getSupplierFunctionsReq,
      suppliersForSelect,
      getSuppliersForSelectReq,
      productions,
      getProductionsReq,
    }: IProps = this.props;
    const { viewMode, suppliers, allSuppliers, functions, allFunctions, gate, productionsInFilter } = this.state;
    const filterActivityParams: any = {
      'filter.activity.allFunctions': allFunctions,
      'filter.activity.allSuppliers': allSuppliers,
      ownerId: this.props.match.params.id,
      size: 1000,
    };
    if (suppliers.length > 0) {
      filterActivityParams['filter.supplier.id'] = suppliers;
    }
    if (functions.length > 0) {
      filterActivityParams['filter.functions.id'] = functions;
    }
    if (gate && gate.length > 0) {
      filterActivityParams['filter.gate.id'] = gate;
    }
    if (productionsInFilter && productionsInFilter.length > 0) {
      filterActivityParams['filter.production.id'] = productionsInFilter;
    }

    if (_.every([production, client, producer], Boolean)) {
      return (
        <AsyncData
          data={[
            {
              asyncData: allActivities,
              asyncGetAction: (p: any) => {
                return getAllActivitiesReq(filterActivityParams);
              },
            },
            { asyncData: supplierFunctions, asyncGetAction: getSupplierFunctionsReq },
            { asyncData: suppliersForSelect, asyncGetAction: getSuppliersForSelectReq },
            { asyncData: productions, asyncGetAction: getProductionsReq },
          ]}
          wrappContent={false}
        >
          {() => {
            return (
              <div className={'print-box'} css={style.printBox}>
                <DateListPrint
                  production={production}
                  activities={allActivities.data}
                  allFunctions={suppliersForSelect.data}
                  allSuppliers={supplierFunctions.data}
                  viewMode={viewMode}
                />
              </div>
            );
          }}
        </AsyncData>
      );
    } else {
      return <div>Loading...</div>;
    }
  }
}

export default connect(
  {
    production,
    productionLoading,
    client,
    producer,
    allActivities,
    supplierFunctions,
    suppliersForSelect,
    productions,
  },
  {
    getProductionReq,
    setProduction,
    getProductionsReq,
    updateProductionDaysReq,
    getAllActivitiesReq,
    setAllActivitiesParams,
    getSupplierFunctionsReq,
    getSuppliersForSelectReq,
  }
)(Item);

const style = {
  printBox: css({
    backgroundColor: mainStyles.Colors.White,
    position: 'absolute',
    left: '0px',
    top: '0px',
    width: '100%',
    padding: '0 20px',
    boxSizing: 'border-box',
  }),
};
