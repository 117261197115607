/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import _ from 'lodash';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { withFormik, FormikProps } from 'formik';
import * as yup from 'yup';
import { compose, lifecycle } from 'recompose';
import { RouteComponentProps } from 'react-router';

import { connect } from '../../../../utils/redux';

import { createSubproductionReq } from './store/actions';
import { production } from './store/selectors';
import { IProduction } from './store/types';

import { getClientsForSelectReq, refreshClientForSelect } from '../Clients/store/actions';
import { clientsForSelect } from '../Clients/store/selectors';
import { ClientListState } from '../Clients/store/types';

import { getClientAgentsReq, setClientAgentsParams } from '../Clients/Agents/store/actions';
import { clientAgents } from '../Clients/Agents/store/selectors';
import { ClientAgentListState } from '../Clients/Agents/store/types';

import { getProducersReq } from '../Producers/store/actions';
import { producers } from '../Producers/store/selectors';
import { IProducer } from '../Producers/store/types';

import {} from './store/selectors';

import { Action } from '../../../../store/types';
import * as validatinShemas from '../../../../utils/validatinShemas';

import PrimaryBtn from '../../../common/buttons/PrimaryBtn';
import OutlineBtn from '../../../common/buttons/OutlineBtn';
import form from '../../../common/form';
import select from '../../../common/form/select';
import page from '../../../common/page';
import routes from '../../../../routes';
import MultiSelectArea from '../../../common/form/multiselect/MultiSelectArea';
import Checkbox from '../../../common/form/InputCheckbox';
import DateRange, { IDataRangeValue } from '../../../common/form/datepicker/DateRange';
import { IDataRangeWithName } from '../../../common/form/datepicker/DateRangeWithName';
import DateRangeListWithName from '../../../common/form/datepicker/DateRangeListWithName';
import AsyncData from '../../../common/async/AsyncData';

import withVisualContext from '../../../../contexts/withVisualContext';
import { IVisualContext } from '../../../../contexts/VisualContext';
interface FormValues {
  name: string;
  productionName: string;
  code: string;
  location: string;
  client: number;
  clientAgents: number[];
  producer: number | null;
  freelancers: number[];
  loadIn: IDataRangeValue;
  loadOut: IDataRangeValue;
  shows: IDataRangeWithName[];
  rehearsals: IDataRangeWithName[];
  hideShows: boolean;
  showTransport: boolean;
  showMachinery: boolean;
}

interface MatchParams {
  production_id?: string;
}

interface OtherProps extends RouteComponentProps<MatchParams> {
  createSubproductionReq: Action<{}>;
  getClientsForSelectReq: Action<{}>;
  refreshClientForSelect: Action<{}>;
  clientsForSelect: ClientListState;
  setClientAgentsParams: Action<{}>;
  getClientAgentsReq: Action<{}>;
  allClientAgents: ClientAgentListState;
  getProducersReq: Action<{}>;
  producers: IProducer[];
  production: IProduction;
  visual: IVisualContext;
}

interface FormProps {}

jsx;
const withLifecycle = lifecycle<OtherProps, {}>({
  componentDidMount() {
    const { getProducersReq, production, setClientAgentsParams, refreshClientForSelect } = this.props;
    refreshClientForSelect();
    getProducersReq({ size: 1000 });
    setClientAgentsParams({ ownerId: production.client.id, size: 1000 });
  },
});

const prepareProductionValues = (values: FormValues) => {
  const clearEmpty = (item: IDataRangeWithName) => item.name || item.range.from || item.range.to;
  return {
    ...values,
    shows: values.shows.filter(clearEmpty),
    rehearsals: values.rehearsals.filter(clearEmpty),
  };
};

const CreateForm = ({
  errors,
  touched,
  values,
  setFieldValue,
  getClientsForSelectReq,
  clientsForSelect,
  getClientAgentsReq,
  allClientAgents,
  producers,
  production,
  visual,
}: OtherProps & FormikProps<FormValues>) => {
  const { FormRow, FormRowSections, FormField, FormError, FormBlock, FieldsSet, ActionsRow, Form, FieldsArea } = form;
  const { Select, Option } = select;
  const { Page, Breadcrumbs, PageHeader } = page;

  const type = production.isMain ? visual.labels.Subproduction : visual.labels.SubSubproduction;
  const nameType = production.isMain ? visual.labels.subproduction : visual.labels.subSubproduction;
  return (
    <Fragment>
      <PageHeader title={`Create ${visual.labels.subproduction}`}>
        <Breadcrumbs
          path={[
            { title: 'Productions', link: routes.Productions },
            { title: `Create ${nameType}`, link: `/productions/${production.id}/create` },
          ]}
        />
      </PageHeader>
      <Page>
        <Form>
          <div>
            <AsyncData
              data={[
                { asyncData: clientsForSelect, asyncGetAction: getClientsForSelectReq },
                { asyncData: allClientAgents, asyncGetAction: getClientAgentsReq },
              ]}
            >
              {() => {
                return (
                  <FieldsArea>
                    {/* MAIN */}
                    <FormBlock>
                      <FieldsSet width={2}>
                        <FormRow>
                          <FormRowSections width={2}>
                            <label htmlFor="productionName">Production</label>
                            <FormField name="productionName" placeholder="Production name" disabled={true} />
                            <FormError name="productionName" errors={errors} touched={touched} />
                          </FormRowSections>

                          <FormRowSections width={2}>
                            <label htmlFor="name">{type}</label>
                            <FormField name="name" placeholder={`${nameType} name`} />
                            <FormError name="name" errors={errors} touched={touched} />
                          </FormRowSections>
                        </FormRow>

                        <FormRow>
                          <FormRowSections width={2}>
                            <label htmlFor="code">Syntax</label>
                            <FormField name="code" placeholder="syntax" />
                            <FormError name="code" errors={errors} touched={touched} />
                          </FormRowSections>

                          <FormRowSections width={2}>
                            <label htmlFor="location">Location</label>
                            <FormField name="location" placeholder="location" />
                            <FormError name="location" errors={errors} touched={touched} />
                          </FormRowSections>
                        </FormRow>

                        <FormRow>
                          <FormRowSections width={2}>
                            <label htmlFor="client">Client</label>
                            <Select
                              disabled={true}
                              scheme={{ id: 'id', title: 'name' }}
                              items={clientsForSelect.data}
                              selected={values.client}
                            >
                              {(item: any, selected: boolean) => {
                                return <Option title={item.name} selected={selected} />;
                              }}
                            </Select>

                            <FormError name="client" errors={errors} touched={touched} />
                          </FormRowSections>

                          <FormRowSections width={2}>
                            <label htmlFor="clientAgents">Client agent</label>
                            <MultiSelectArea
                              scheme={{ id: 'id', title: 'name' }}
                              items={_.get(allClientAgents, 'data', [])}
                              selected={values.clientAgents}
                              onChange={(value) => {
                                setFieldValue('clientAgents', value);
                              }}
                            >
                              {(item: any, selected: boolean) => {
                                return <Option title={item.name} selected={selected} />;
                              }}
                            </MultiSelectArea>
                            <FormError name="clientAgents" errors={errors} touched={touched} />
                          </FormRowSections>
                        </FormRow>

                        <FormRow>
                          <FormRowSections width={2}>
                            <label htmlFor="producer">Production manager</label>
                            <Select
                              scheme={{ id: 'id', title: 'name' }}
                              items={producers.filter((item) => item.isActive)}
                              selected={values.producer}
                              onChange={(value) => {
                                setFieldValue('producer', value);
                              }}
                            >
                              {(item: any, selected: boolean) => {
                                return <Option title={item.name} selected={selected} />;
                              }}
                            </Select>
                            <FormError name="producer" errors={errors} touched={touched} />
                          </FormRowSections>

                          <FormRowSections width={2}>
                            <label htmlFor="freelancers">Freelancers</label>
                            <MultiSelectArea
                              items={producers.filter((item) => item.isActive && item.isFreelancer)}
                              selected={values.freelancers}
                              scheme={{ id: 'id', title: 'name' }}
                              onChange={(value) => {
                                setFieldValue('freelancers', value);
                              }}
                            />
                            <FormError name="freelancers" errors={errors} touched={touched} />
                          </FormRowSections>
                        </FormRow>
                      </FieldsSet>
                    </FormBlock>

                    {/* LOAD DATES */}
                    <FormBlock>
                      <FieldsSet width={2}>
                        <FormRow>
                          <DateRange
                            value={values.loadIn}
                            fromLabel={'Load in from'}
                            toLabel={'Load in to'}
                            onChange={(value) => {
                              setFieldValue('loadIn', value);
                            }}
                          />
                        </FormRow>
                        <FormError name="loadIn" errors={errors} touched={touched} />

                        <FormRow>
                          <DateRange
                            value={values.loadOut}
                            fromLabel={'Load out from'}
                            toLabel={'Load out to'}
                            onChange={(value) => {
                              setFieldValue('loadOut', value);
                            }}
                          />
                        </FormRow>
                        <FormError name="loadIn" errors={errors} touched={touched} />
                      </FieldsSet>
                    </FormBlock>

                    {/* SHOWS DATES */}
                    <FormBlock>
                      <FieldsSet width={2}>
                        <DateRangeListWithName
                          nameLabel="Shows name"
                          fromLabel="Show start"
                          toLabel="Show end"
                          value={values.shows}
                          error={<FormError name="shows" errors={errors} touched={touched} />}
                          btn={<OutlineBtn title={'Add new show'} />}
                          onChange={(value) => {
                            setFieldValue('shows', value);
                          }}
                          checkboxForHiding="Hide in the schedule"
                          hidedShow={values.hideShows}
                          onHideShow={(e: any) => {
                            setFieldValue('hideShows', e.currentTarget.checked);
                          }}
                        />
                      </FieldsSet>
                    </FormBlock>

                    {/* REHEARSALS DATES */}
                    <FormBlock>
                      <FieldsSet width={2}>
                        <DateRangeListWithName
                          nameLabel="Rehearsals name"
                          fromLabel="Rehearsals start"
                          toLabel="Rehearsals end"
                          value={values.rehearsals}
                          error={<FormError name="rehearsals" errors={errors} touched={touched} />}
                          btn={<OutlineBtn title={'Add new rehearsal'} />}
                          onChange={(value) => {
                            setFieldValue('rehearsals', value);
                          }}
                        />
                      </FieldsSet>
                    </FormBlock>
                    <FormBlock>
                      <FieldsSet width={1}>
                        <FormRow>
                          <label css={checkboxLabel}>
                            <Checkbox
                              name="showTransport"
                              checked={values.showTransport}
                              onChange={(e: any) => {
                                setFieldValue('showTransport', e.currentTarget.checked);
                              }}
                            />{' '}
                            Show Transport schedule
                          </label>
                          <label css={checkboxLabel}>
                            <Checkbox
                              name="showMachinery"
                              checked={values.showMachinery}
                              onChange={(e: any) => {
                                setFieldValue('showMachinery', e.currentTarget.checked);
                              }}
                            />{' '}
                            Show Machinery schedule
                          </label>
                        </FormRow>
                      </FieldsSet>
                    </FormBlock>
                  </FieldsArea>
                );
              }}
            </AsyncData>

            <ActionsRow>
              <Link to={production.id ? routes.Production.replace(':id', '' + production.id) : routes.Productions}>
                <OutlineBtn title="Cancel" isWide={false} />
              </Link>
              <PrimaryBtn title={`Create ${nameType}`} isWide={false} />
            </ActionsRow>
          </div>
        </Form>
      </Page>
    </Fragment>
  );
};

export const exchangeFormic = withFormik<FormProps & OtherProps, FormValues>({
  enableReinitialize: true,
  validate: (values, props) => {},
  validationSchema: yup.object({}).shape({
    clientAgents: validatinShemas.vIdListReq,
    producer: validatinShemas.vIdReq,
    client: yup.string().required(validatinShemas.REQUIRE_MESSAGE),
    location: yup.string().required(validatinShemas.REQUIRE_MESSAGE),
    code: yup.string().required(validatinShemas.REQUIRE_MESSAGE),
  }),
  mapPropsToValues: ({ production }) => {
    return {
      name: '',
      productionName: _.get(production, 'name', ''),
      location: '',
      // code: '',
      code: _.get(production, 'code', ''),
      client: _.get(production, 'client.id', 0),
      // clientAgents: [],
      // clientAgents: _.get(production, ['clientAgents', '0', 'id'], 0),
      clientAgents: production.clientAgents.map((client: any) => client.id),
      // producer: null,
      producer: _.get(production, 'producer.id', null),
      freelancers: [],
      loadIn: _.get(production, 'loadIn', { from: '', to: '' }),
      loadOut: _.get(production, 'loadOut', { from: '', to: '' }),
      shows: [{ name: '', range: { from: '', to: '' } }],
      rehearsals: [{ name: '', range: { from: '', to: '' } }],
      hideShows: true,
      showTransport: true,
      showMachinery: true,
    };
  },
  handleSubmit: (values, { props, setSubmitting }) => {
    props.createSubproductionReq({
      productionId: props.match.params.production_id,
      values: prepareProductionValues(values),
    });
  },
  displayName: 'EditForm',
});

export default compose<OtherProps & FormikProps<FormValues>, {}>(
  connect(
    { production, clientsForSelect, allClientAgents: clientAgents, producers },
    {
      createSubproductionReq,
      getClientsForSelectReq,
      refreshClientForSelect,
      getClientAgentsReq,
      setClientAgentsParams,
      getProducersReq,
    }
  ),
  withVisualContext,
  withLifecycle,
  exchangeFormic
)(CreateForm);

const checkboxLabel = css({
  cursor: 'pointer',
  marginTop: '12px',
  display: 'flex !important',
  alignItems: 'center  !important',
  columnGap: '10px',
});
