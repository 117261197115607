import * as func from './func';

const PATH_DELIMITER = '.';

export const stringToPath = (str) => {
  return func.isString(str) ? str.split(PATH_DELIMITER) : str;
};

export const stringToBoolean = (value) => {
  if (value && typeof value === 'string') {
    if (value.toLowerCase() === 'true') return true;
    if (value.toLowerCase() === 'false') return false;
  }
  return value;
};
