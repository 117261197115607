import { IList, IItem, Params, IFilterParams } from '../../../../../utils/store/state/state';

export interface IProduction {
  id: number;
  name: string;
  mainActivities: IActivity[];
  shows: IActivity[];
  client: any;
  clientAgents: any;
  code: string;
  // code: string | null;
  location: string;
  isMain: boolean;
  loadIn: any;
  loadOut: any;
  producer: any;
  freelancers: any;
  main: IProduction;
  sub: IProduction[];
  file: string;
  general: any;
  canHaveSub: boolean;
  hideShows: boolean;
  showTransport: boolean;
  showMachinery: boolean;
}

export interface IDataRange {
  from: string | number;
  to: string | number;
}

export enum ActivityTypes {
  ACTIVITY = 'Activity',
  SHOW = 'Show',
  REHEARSAL = 'Rehearsal',
}

export interface IActivity {
  id: number;
  isMain: boolean;
  name: string;
  range: IDataRange;
  remarks: string;
  production: IProduction;
  type: ActivityTypes;
}

interface IListParams extends Params<IFilterParams> {
  archive: boolean;
}

export type ProductionListState = IList<IProduction, IListParams>;
export type ProductionItemState = IItem<IProduction, any>;
export type ProductionPrintState = IItem<any, any>;
export type ProductionPrintInfoState = IItem<any, any>;
