/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import moment from 'moment';

import { Component, Fragment } from 'react';

import { IProduction } from '../../store/types';
import { IActivity } from '../store/types';

import * as utils from '../utils';

import DayPrint from './DayPrint';
import CheckAcl from '../../../CheckAcl';

import { getMatchTypeView, ViewModes } from '../Item';

import * as config from '../../../../../../config';
import * as mainStyles from '../../../../../../styles/styles';
import withVisualContext from '../../../../../../contexts/withVisualContext';

export interface IProps {
  production: IProduction;
  activities: IActivity[];
  isPrintView?: boolean;
  viewMode?: ViewModes;

  allFunctions: any;
  allSuppliers: any;
  visual: { logo: string; isCopy: boolean };
}

export interface IState {}

jsx;
class DateListPrint extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  convertActivitesRangeToTimestamp = (activities: IActivity[]): IActivity[] => {
    return activities.map((activity) => {
      const range = { from: moment.utc(activity.range.from).valueOf(), to: moment.utc(activity.range.to).valueOf() };
      return Object.assign({}, activity, { range: range });
    });
  };

  getActivityByDate = (activities: any[], date: any) => {
    const result = activities.reduce((result, activity) => {
      const intersection = utils.getIntersectionRanges(date, activity.range);
      return intersection ? [...result, { ...activity, intersection }] : result;
    }, []);

    return result;
  };

  filterDataByType = (data: any) => {
    const { viewMode = ViewModes.mixed } = this.props;
    const math = getMatchTypeView(viewMode);

    return data.filter((item: any) => {
      return math.includes(item.type);
    });
  };

  render() {
    const { production, activities, allFunctions, allSuppliers, viewMode } = this.props;
    const rangeRepresentation = utils.getRangeOfWorkDays(production.loadIn.from, production.loadOut.to, 'day');
    const activitiesWithConvertRange = this.convertActivitesRangeToTimestamp(this.filterDataByType(activities));
    // const printActivities = this.getActivityByDate(activitiesWithConvertRange, workDay);

    const productionDate = `${moment
      .utc(production.loadIn.from)
      .format(`${config.GRID_DATE_FORMAT}, YYYY`)} - ${moment
      .utc(production.loadOut.to)
      .format(`${config.GRID_DATE_FORMAT}, YYYY`)} `;

    const productionInfo = (
      <tr className={'production-header'} css={styles.productionInfoBox}>
        <th colSpan={5}>
          <div css={styles.productionInfo}>
            <div css={styles.productionInfoColl}>
              <div css={styles.productionInfoRow}>
                <div css={styles.productionInfoRowLabel}>Production name:</div>
                <div css={styles.productionInfoRowValue}>{production.name}</div>
              </div>

              <div css={styles.productionInfoRow}>
                <div css={styles.productionInfoRowLabel}>Production area:</div>
                <div css={styles.productionInfoRowValue}>{production.location}</div>
              </div>

              <div css={styles.productionInfoRow}>
                <div css={styles.productionInfoRowLabel}>Production date:</div>
                <div css={styles.productionInfoRowValue}>{productionDate}</div>
              </div>
            </div>
            <div css={styles.productionInfoColl}>
              <CheckAcl resources="client" actions="R">
                <div css={styles.productionInfoRow}>
                  <div css={styles.productionInfoRowLabel}>Client name:</div>
                  <div css={styles.productionInfoRowValue}>{production.client.name}</div>
                </div>
              </CheckAcl>

              <div css={styles.productionInfoRow}>
                <div css={styles.productionInfoRowLabel}>Producer:</div>
                <div css={styles.productionInfoRowValue}>{production.producer.name}</div>
              </div>
            </div>
          </div>
        </th>
        <th colSpan={2}>
          <div css={styles.productionLogos}>
            <img src={production.file ? production.file : production.client.file} alt="" />
            {!this.props.visual.isCopy && <img src={this.props.visual.logo} alt="" />}
          </div>
        </th>
      </tr>
    );

    return (
      <Fragment>
        {rangeRepresentation.map((workDay: any, index: number) => {
          const dayHeader = (
            <tr>
              <th colSpan={7}>
                <div className={'day-header'} css={styles.dayTitle}>
                  Production schedule {workDay.from.format(`${config.GRID_DATE_FORMAT}, YYYY`)}
                </div>
              </th>
            </tr>
          );

          const additionalHeader = index ? (
            dayHeader
          ) : (
            <Fragment>
              {dayHeader}
              {productionInfo}
            </Fragment>
          );

          return (
            <div className={'day-box'} key={index}>
              {/*{!index && productionInfo}*/}

              <div className={'day-table'} css={styles.dayTableBox}>
                <DayPrint
                  production={production}
                  workDay={workDay}
                  activities={this.getActivityByDate(activitiesWithConvertRange, workDay)}
                  additionalPrintHeader={additionalHeader}
                  allFunctions={allFunctions}
                  allSuppliers={allSuppliers}
                  viewMode={viewMode}
                />
              </div>
            </div>
          );
        })}
      </Fragment>
    );
  }
}

export default withVisualContext(DateListPrint);

const styles = {
  dayTitle: [
    mainStyles.H2,
    css({
      textAlign: 'left',
      width: 'auto',
      paddingBottom: '20px',
    }),
  ],
  productionInfoBox: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '20px',
  }),

  productionInfo: [
    mainStyles.MainText,
    css({
      display: 'flex',
    }),
  ],

  productionInfoColl: css({
    marginRight: '40px',
  }),

  productionInfoRow: css({
    display: 'flex',
    textAlign: 'left',
  }),

  productionInfoRowLabel: css({
    marginRight: '20px',
    width: '120px',
  }),
  productionInfoRowValue: css({}),

  productionLogos: css({
    display: 'flex',

    '& img': {
      marginLeft: '20px',
      height: '80px',
      marginTop: '-30px',
      objectFit: 'contain',
      [`@media (max-width: 1024px)`]: {
        maxWidth: '180px',
      },
    },
  }),

  dayTableBox: css({
    paddingTop: '20px',
    '& .grid-header': {
      backgroundColor: mainStyles.Colors.AltGrey,
    },
  }),
};
