import { put, call, takeEvery } from 'redux-saga/effects';
import * as actions from './actions';
// utils
import _ from 'lodash';
import { getResponseMessage } from '../../../../../utils/response';
// other
import { toast } from 'react-toastify';

export default function* watchReducersAsync() {
  yield takeEvery(actions.GET_PRODUCTION_SUPPLIERS_REQ, getProductionSuppliersAsync);
  yield takeEvery(actions.UPDATE_PRODUCTION_SUPPLIER_REQ, updateProductionSupplierAsync);
  yield takeEvery(actions.DELETE_PRODUCTION_SUPPLIER_REQ, deleteProductionSupplierAsync);
  yield takeEvery(actions.CREATE_PRODUCTION_SUPPLIER_REQ, createProductionSupplierAsync);
}

// GET PRODUCTION SUPPLIERS
export function* getProductionSuppliersAsync({ api, payload: { productionId, ...restParams } }: any) {
  const params = { size: 20, user: false, page: 0, ...restParams };
  try {
    const path = `/productions/${productionId}/suppliers`;
    const response = yield call(api.get, path, { params });
    const data = _.get(response, 'data.data');
    const meta = _.get(response, 'data.meta');
    yield put(actions.getProductionSuppliersReqSuccess({ data, params }, meta));
  } catch (err) {
    yield put(actions.getProductionSuppliersReqError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

// UPDATE PRODUCTION SUPPLIER
export function* updateProductionSupplierAsync({ api, payload: { productionId, prodSupplierId, data } }: any) {
  try {
    const path = `/productions/${productionId}/suppliers/${prodSupplierId}`;
    const response = yield call(api.post, path, data);
    const supplier = response.data.data[0];
    yield put(actions.updateProductionSupplierReqSuccess(supplier));
    //yield call([toast, 'success'], 'Supplier agent successfully updated!');
  } catch (err) {
    yield put(actions.updateProductionSupplierReqError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

// DELETE PRODUCTION SUPPLIER
export function* deleteProductionSupplierAsync({ api, payload: { productionId, prodSupplierId } }: any) {
  try {
    const path = `/productions/${productionId}/suppliers/${prodSupplierId}`;
    const response = yield call(api.delete, path);
    yield put(actions.deleteProductionSupplierReqSuccess({ id: prodSupplierId }));
    //yield call([toast, 'success'], 'Production supplier successfully deleted!');
  } catch (err) {
    yield put(actions.deleteProductionSupplierReqError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

// CREATE PRODUCTION SUPPLIER
export function* createProductionSupplierAsync({ api, payload: { productionId, data } }: any) {
  try {
    const path = `/productions/${productionId}/suppliers`;
    const response = yield call(api.post, path, data);
    const supplier = response.data.data[0];
    yield put(actions.createProductionSupplierReqSuccess(supplier));
    yield put(actions.getProductionSuppliersReq({ productionId, size: 20, page: 0 }));
    //yield call([toast, 'success'], 'Production supplier successfully created!');
  } catch (err) {
    yield put(actions.createProductionSupplierReqError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}
